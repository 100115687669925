import React from 'react';
import { useTheme } from "@mui/material/styles";
import { TextField, Select, MenuItem, FormControlLabel, Radio, Checkbox, Box, InputLabel, FormControl } from "@mui/material";
import { createSelectObjectCondition } from '../utils/SearchConditionUtils';

const SearchConditions = ({ searchConditions, onConditionChange, onDateRangeFromChange, onDateRangeChange, onSearch, itemsPerRow }) => {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();

  const handleChange = (event, conditionName) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    onConditionChange(conditionName, value);
  };

  const handleDateRangeChange = (conditionName, newValue) => {
    onDateRangeChange(conditionName, newValue);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch();
    }
  };

  const renderInput = (condition) => {
    switch(condition.type) {
      case 'date':
        return (
          <TextField 
            type="date" 
            label={condition.name}
            id={condition.name}
            InputLabelProps={{ shrink: true }}
            value={condition.value}
            onChange={event => handleChange(event, condition.name)}
            variant="outlined"
            size="small"
            inputProps={{
              style: {
                height: "18px",
                width:"96px",
              },
            }}
          />
        );
      case 'date-range':
        return (
          <Box display="flex" gap={1}>
            <TextField 
              type="date" 
              label={condition.name}
              InputLabelProps={{ shrink: true }}
              id={condition.name + 'From'} 
              value={condition.value.from} 
              onChange={event => handleDateRangeChange(condition.name, { from: event.target.value })}
              variant="outlined"
              size="small"
              inputProps={{
                style: {
                  height: "18px",
                  width:"96px",
                },
              }}
            /> 
            <span>~</span>
            <TextField 
              type="date" 
              label={condition.name}
              InputLabelProps={{ shrink: true }}
              id={condition.name + 'To'} 
              value={condition.value.to}
              onChange={event => handleDateRangeChange(condition.name, { to: event.target.value })}
              variant="outlined"
              size="small"
              inputProps={{
                style: {
                  height: "18px",
                  width:"96px",
                },
              }}
            />
          </Box>
        );
      case 'select':
        if(condition.use_code){
          condition = createSelectObjectCondition(condition, condition.code);
        }
        return (
          <FormControl style={{minWidth: 120, maxWidth: 180}}>
            <InputLabel variant="outlined" shrink={true}>{condition.name}</InputLabel>
            <Select
              id={condition.name} 
              label={condition.name}
              value={condition.value}
              notched={true}
              onChange={event => handleChange(event, condition.name)}
              variant="outlined"
              size="small"
            >
              {condition.options.map((option, index) => (
                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case 'radio':
        return condition.options.map((option, index) => (
          <FormControlLabel 
            key={index} 
            control={
              <Radio 
                key={index} 
                name={condition.name} 
                value={option.value}
                checked={condition.value === option.value}
                onChange={event => handleChange(event, condition.name)}
              />
            }
            label={option.value}
          />
        ));
      case 'checkbox':
        return (
          <Checkbox
            id={condition.name} 
            checked={condition.value}
            onChange={event => handleChange(event, condition.name)}
          />
        );
      default:
        return (
          <TextField 
            type="text" 
            label={condition.name}
            id={condition.name}
            InputLabelProps={{ shrink: true }}
            value={condition.value} 
            onChange={event => handleChange(event, condition.name)}
            variant="outlined"
            size="small"
            inputProps={{
              style: {
                height: "18px",
              },
            }}
          />
        );
    }
  };

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%" onKeyDown={handleKeyDown}>
      {searchConditions.map((condition, index) => (
        <Box key={index} width={`${100 / itemsPerRow}%`} m={1}>
          {/* <label htmlFor={condition.name}>{condition.name}</label> */}
          {renderInput(condition)}
        </Box>
      ))}
    </Box>
  );
};

export default SearchConditions;
