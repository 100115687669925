// eslint-disable-next-line no-unused-vars
import { createSelectObject } from '../../utils/agGridColumnUtils';

export const getMainColumns = () => {
  return [
    { field: "common_code", headerName: "코드", edit: true, minWidth: 80, maxWidth: 100, sideBarEditType: "text", headerCheckboxSelection: true, checkboxSelection: true,},
    { field: "common_code_name", headerName: "코드명", edit: true, minWidth: 80, maxWidth: 180, sideBarEditType: "text",},
    { field: "is_used", headerName: "사용여부", edit: true, minWidth: 80, maxWidth: 100, cellDataType: "boolean", sideBarEditType: "checkbox",},
    { field: "remarks", headerName: "비고", edit: true, minWidth: 80, maxWidth: 120, sideBarEditType: "text",},
  ];
};

export const getSubColumns = () => {
  return [
    { field: "detail_code", headerName: "상세코드", edit: true, minWidth: 80, maxWidth: 120, sideBarEditType: "text", headerCheckboxSelection: true, checkboxSelection: true,},
    { field: "detail_code_name", headerName: "상세코드명", edit: true, minWidth: 80, maxWidth: 200, sideBarEditType: "text",},
    { field: "sort_order", headerName: "순번", cellDataType: "number", edit: true, minWidth: 50, maxWidth: 80, sideBarEditType: "number",},
    { field: "ref1", headerName: "참고1", edit: true, minWidth: 80, maxWidth: 100, sideBarEditType: "date",},
    { field: "ref2", headerName: "참고2", edit: true, minWidth: 80, maxWidth: 100, sideBarEditType: "text",},
    { field: "ref3", headerName: "참고3", edit: true, minWidth: 80, maxWidth: 100, sideBarEditType: "text",},
    { field: "ref4", headerName: "참고4", edit: true, minWidth: 80, maxWidth: 100, sideBarEditType: "text",},
    { field: "ref5", headerName: "참고5", edit: true, minWidth: 80, maxWidth: 100, sideBarEditType: "text",},
    { field: "remarks", headerName: "비고", edit: true, minWidth: 80, maxWidth: 150, sideBarEditType: "text",},
  ];
};
