import { atomFamily } from 'recoil';
import { calculateDate } from "../../utils/Formatter";

const id = 'AD001';
export const rowsState = atomFamily({
  key: (tabKey) => `${id}-rowsState${tabKey}`,
  default: []
});

export const loadingState = atomFamily({
  key: (tabKey) => `${id}-loadingState${tabKey}`,
  default: false
});

export const searchConditionsState = atomFamily({
  key: (tabKey) => `${id}-searchConditionsState${tabKey}`,
  default: [
    { id: 'common_code', name: '코드', type: 'text', value: '' },
    { id: 'common_code_name', name: '코드명', type: 'text', value: '' },
    { id: 'start_date', name: '시작일자', type: 'date', value: calculateDate(-7) },
    { id: 'code_select1', type: 'select', use_code: true,  code: 'CA10', },
    { id: 'code_select2', type: 'select', use_code: true,  code: 'AB04', },
    { id: 'radio1', name: '라디오1', type: 'radio', value: '', options: [{value: 'option1', label: 'Option 1'}, {value: 'option2', label: 'Option 2'}] },
    { id: 'radio2', name: '라디오2', type: 'radio', value: '', options: [{value: 'option1', label: 'Option 1'}, {value: 'option2', label: 'Option 2'}] },
    { id: 'check1', name: '체크1', type: 'checkbox', value: true },
    { id: 'check2', name: '체크2', type: 'checkbox', value: false },
    { id: 'date_range', name: '검색기간', type: 'date-range', value: { from: calculateDate(-7), to: calculateDate(7) } }, 
  ]
});
 
export const searchConditionitemsPerRow = 6;