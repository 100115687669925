// useSaveData.js
import { useState } from 'react';
import axios from 'axios';

const useSaveData = (saveMainApi, saveSubApi, onFetchMainData, onFetchSubData) => {
  const [loading, setLoading] = useState(false);

  const saveData = async (unsavedData, selectedGrid) => {
    setLoading(true);
    const SERVER_ENDPOINT = 'https://work.jhseo.me:8004/';

    try {
      const token = localStorage.getItem('token');

      const headers = {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      };
      
      const uri = selectedGrid === "main" ? saveMainApi : saveSubApi;

      const response = await axios.post(SERVER_ENDPOINT+uri, unsavedData, { headers : headers });
  
      // Handle response
      if (response.status === 200) {
        console.log('Data saved successfully');
        // If needed, you can refresh the data
        if(selectedGrid === "main"){
          onFetchMainData();
        }else{
          onFetchSubData();
        }
      } else {
        console.error('Failed to save data:', response);
      }
    } catch (error) {
      console.error('An error occurred while saving data:', error);
    } finally {
      setLoading(false);
    }
  };

  return [saveData, loading];
};

export default useSaveData;