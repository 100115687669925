import React, { useRef, useState, useEffect } from "react";
import { ArrowBackIos, ArrowForwardIos, Close } from "@material-ui/icons";
import MenuIcon from '@material-ui/icons/Menu';
import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useRecoilState } from 'recoil';
import { sidebarBrokenState, sidebarToggledState } from './SideBar';

const TabHeader = ({ tabs, currentTab, setCurrentTab, handleTabClose }) => {
  const [broken] = useRecoilState(sidebarBrokenState);
  const [toggled, setToggled] = useRecoilState(sidebarToggledState);
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const TabItem = styled.div`
    display: inline-flex;
    margin-right: 1px;
    cursor: pointer;
    padding: 1px;
    border: 0px solid #000;
    border-radius: 5px;
    align-items: center;
    background: ${props => (props.isDarkMode ? (props.isActive? colors.grey[600] : colors.grey[800]) : (props.isActive ? colors.blueAccent[900] : colors.grey[900]))};
    height: 25px;
    width: 120px;
  `;
  
  const containerRef = useRef();
  const [scrollPosition, setScrollPosition] = useState({ left: 0, width: 0 });
  const [tabRefs, setTabRefs] = useState({});

  useEffect(() => {
    // Create a ref for each tab when tabs array changes
    setTabRefs(tabs.reduce((acc, tab) => ({ ...acc, [tab]: React.createRef() }), {}));
  }, [tabs]);

  useEffect(() => {
    const { scrollLeft, scrollWidth, offsetWidth } = containerRef.current;
    setScrollPosition({ left: scrollLeft, width: scrollWidth - offsetWidth });
  }, [tabs]);

  useEffect(() => {
    // Auto scroll to current tab
    if (currentTab != null && tabRefs[currentTab] && tabRefs[currentTab].current) {
        setTimeout(() => {
            tabRefs[currentTab].current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }, 250);  // Adjust this time as needed
    }
    // eslint-disable-next-line
  }, [currentTab]);

  useEffect(() => {
    // This will cause the component to re-render whenever currentTab changes
  }, [currentTab]);

  const scroll = (scrollOffset) => {
    containerRef.current.scrollLeft += scrollOffset;
    setScrollPosition({ left: containerRef.current.scrollLeft, width: containerRef.current.scrollWidth - containerRef.current.offsetWidth });
  };

  return (
    <div style={{ display: "flex" }}>
      {broken && (
        <MenuIcon onClick={() => setToggled(!toggled)}/>
      )}
      {scrollPosition.width > 0 && (
        <ArrowBackIos
          style={{ cursor: "pointer" }}
          onClick={() => scroll(-100)}
          disabled={scrollPosition.left <= 0}
        />
      )}
      <div
        ref={containerRef}
        style={{ overflow: "hidden", whiteSpace: "nowrap" }}
      >
        {tabs.map((tab, index) => (
          <TabItem 
            key={tab.key} 
            isActive={currentTab.key === tab.key}
            onClick={() => setCurrentTab(tab)}
          >
            <div style={{ fontWeight: "bold", fontSize: "10pt", paddingLeft:"5px"}}>
              {tab.menu_name}
            </div>
            <div style={{ flex: 1 }} />
            <Close
              style={{ cursor: "pointer", paddingLeft: "5px", paddingRight: "5px", }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                
                handleTabClose(tab);
                
              }}
            />
          </TabItem>
        ))}
      </div>
      {scrollPosition.width > 0 && (
        <ArrowForwardIos
          style={{ cursor: "pointer" }}
          onClick={() => scroll(100)}
          disabled={scrollPosition.left >= scrollPosition.width}
        />
      )}
    </div>
  );
};

export default TabHeader;
