export const createSelectObject = (code) => {
  const storedObject = JSON.parse(localStorage.getItem("common_code")).find(
    (obj) => obj.common_code === code
  );
  const systemValues = storedObject.children.map((child) => child.detail_code);

  return {
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: systemValues,
    },
    valueFormatter: (params) => {
      const matchedChild = storedObject.children.find(
        (child) => child.detail_code === params.value
      );
      return matchedChild ? matchedChild.detail_code_name : "";
    },
  };
};

export const createSelectOption = (code) => {
  let commonCode  = localStorage.getItem('common_code');
  let codeObj = '';
  if(commonCode) {
    codeObj = JSON.parse(commonCode).find(obj => obj.common_code === code);
    // Add the logic to be executed when codeObj is found.
  } else {
      console.error("No 'common_code' found in localStorage.");
      // Add the logic to be executed when 'common_code' is not found.
  }

  if(codeObj === ''){
    return null;
  }else{
    let options = codeObj.children.map(c => ({
      value: c.detail_code,
      label: c.detail_code_name
    }));

    return { options: options };
  }   
}

export const columnDefsGenerator = (columnDefs, ActionCellRenderer) => {
  
  return [
    {
      headerName: "",
      field: "rowStatusCode",
      maxWidth: 30,
      minWidth: 30,
      editable: false,
      hide: true,
      checkboxSelection: true,
      headerCheckboxSelection: true, 
    },
    {
      headerName: "",
      field: "rowStatusName",
      maxWidth: 50,
      minWidth: 50,
      hide: true,
      editable: false,
    },
    ...columnDefs.map((columnDef, index) => {
      if(columnDef.use_code){
        return {...columnDef,...createSelectObject(columnDef.code)};
      }else{
        return columnDef;
      }
    }),
    {
      field: 'action',
      headerName: 'ACTION',
      cellRenderer: ActionCellRenderer,
      minWidth: 155,
      maxWidth: 165,
      pinned: 'right'      
    }
    
  ];
};

export const getDefaultRowSpan = (params) => {
  if (!params.data) return 1;

  if(params.data.seq === 1){
    return params.data.cell_span;
  }
  return 1;
};

export const getDefaultCellSpanRule = (params) => {
  return !!params.data && !!params.data.transaction_no && (params.data.seq === 1);
}