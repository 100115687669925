import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { useAuth } from './auth';
import App from './App';
import Login from './login';
import jwtDecode from 'jwt-decode';


const Main = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  useEffect(() => {
    const checkTokenValidity = () => {
      const token = localStorage.getItem('token');
  
      if (!token || token === 'undefined') {
        if (isLoggedIn !== false) setIsLoggedIn(false);
        return;
      }
  
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
  
        if (decodedToken.exp < currentTime) {
          console.log("Token is expired.");
          if (isLoggedIn !== false) setIsLoggedIn(false);
        } else {
          console.log("Token is valid.");
          if (isLoggedIn !== true) setIsLoggedIn(true);
        }
      } catch (err) {
        console.error("Invalid token.", err);
        if (isLoggedIn !== false) setIsLoggedIn(false);
      }
    };
  
    checkTokenValidity();
  }, [setIsLoggedIn, isLoggedIn]);
  

  if (isLoggedIn === null) {
    return <Login />;
  } else if (isLoggedIn) {
    return <RecoilRoot><App /></RecoilRoot>;
  } else {
    return <Login />;
  }
};

export default Main;