import React, { useState } from 'react';
import CommonMenuTemplate1 from '../CommonMenuTemplate1';
import { getColumns } from './CF003Columns';
import { searchConditionsState, searchConditionitemsPerRow } from './CF003State';

const CF003 = ({ tab, onChangeMain, resetChangedMain, handleGridResultMessage}) => {
  const [columnDefs] = useState(getColumns);
  
  // eslint-disable-next-line no-unused-vars
  const onRowClicked = (event) => {
    //alert(event.data.common_code_name);
    //setClickedRow(event.data);
  }

  const menuGridProps = {
    columnDefs: columnDefs,
    //onRowClicked:onRowClicked,
  };

  return (
      <CommonMenuTemplate1 
        menuId={tab.menu_id}
        menuName={tab.menu_name}
        searchConditionsState={searchConditionsState(tab.key)}
        searchApi={'config/items/'}
        saveApi={'config/items/'}
        gridProps={menuGridProps}
        itemsPerRow={searchConditionitemsPerRow}
        keyColumn={'item_code'}
        useModal={false}
        onChangeMain={onChangeMain}
        resetChangedMain={resetChangedMain}
        isReport={true}
        handleGridResultMessage={handleGridResultMessage}
      />
  );
};

export default CF003;