import { atomFamily } from 'recoil';
// eslint-disable-next-line no-unused-vars
import { calculateDate } from "../../utils/Formatter";

const id = 'CF001';
export const rowsState = atomFamily({
  key: (tabKey) => `${id}-rowsState${tabKey}`,
  default: []
});

export const loadingState = atomFamily({
  key: (tabKey) => `${id}-loadingState${tabKey}`,
  default: false
});

export const searchConditionsState = atomFamily({
  key: (tabKey) => `${id}-searchConditionsState${tabKey}`,
  default: [    
    { id: 'member_code', name: '회원코드', type: 'text', value: '' },
    { id: 'member_name', name: '회원명', type: 'text', value: '' },
  ]
});
 
export const searchConditionitemsPerRow = 5;