// eslint-disable-next-line no-unused-vars
import { formatAMPM, formatAmount } from "../../utils/Formatter";
// eslint-disable-next-line no-unused-vars
import { createSelectObject, createSelectOption } from '../../utils/agGridColumnUtils';

export const getColumns = () => {
  return [
    { headerName: "사업장", field: "business_place", sortable: true, minWidth: 100, maxWidth: 120, pinned: "left",},
    { headerName: "회원번호", field: "member_code", sortable: true, editable: true, minWidth: 80, maxWidth: 90, pinned: "left",},
    { headerName: "회원명", field: "member_name", sortable: true, minWidth: 100, maxWidth: 110, pinned: "left",},
    { headerName: "회원구분", field: "member_type", sortable: true, minWidth: 90, maxWidth: 110,},
    { headerName: "설명", field: "description", sortable: true, minWidth: 140, maxWidth: 160,},
    { headerName: "암호", field: "password", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "모회원", field: "parent_member", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "사용여부", field: "use_or_not", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "생산자표시", field: "producer_mark", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "농원명", field: "farm_name", sortable: true, minWidth: 120, maxWidth: 140,},
    { headerName: "은행코드", field: "bank_code", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "계좌번호", field: "account_number", sortable: true, minWidth: 140, maxWidth: 170,},
    { headerName: "계좌주", field: "account_holder", sortable: true, minWidth: 120, maxWidth: 120,},
    { headerName: "계통출하", field: "shipment_route", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "전화", field: "phone_number", sortable: true, minWidth: 120, maxWidth: 150,},
    { headerName: "팩스", field: "fax_number", sortable: true, minWidth: 120, maxWidth: 150,},
    { headerName: "휴대폰", field: "mobile_number", sortable: true, minWidth: 120, maxWidth: 150,},
    { headerName: "우편번호", field: "postal_code", sortable: true, minWidth: 120, maxWidth: 120,},
    { headerName: "주소", field: "address", sortable: true, minWidth: 120, maxWidth: 150,},
    { headerName: "메모", field: "memo", sortable: true, minWidth: 120, maxWidth: 150,},
    { headerName: "입력자", field: "input_user", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "입력시간", field: "input_time", sortable: true, filter: "agDateColumnFilter", minWidth: 170, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value),},
    { headerName: "수정자", field: "update_user", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "수정시간", field: "update_time", sortable: true, filter: "agDateColumnFilter", minWidth: 170, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value),},
  ];
};

