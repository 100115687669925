// eslint-disable-next-line no-unused-vars
import { formatAMPM, formatAmount } from "../../utils/Formatter";
// eslint-disable-next-line no-unused-vars
import { createSelectObject, createSelectOption } from '../../utils/agGridColumnUtils';

export const getColumns = () => {
  return [
    { headerName: "idx", field: "idx", sortable: true, minWidth: 70, maxWidth: 90, type: "numericColumn", pinned: "left",},
    { headerName: "회원구분", field: "member_type", sortable: true, minWidth: 80, maxWidth: 110, pinned: "left",},
    { headerName: "고객구분", field: "transaction_type", sortable: true, editable: true, minWidth: 100, maxWidth: 120, pinned: "left",},
    { headerName: "금액 ~에서", field: "amount_from", editable: true, minWidth: 80, maxWidth: 120, pinned: "left", type: "numericColumn", sortable: true, cellDataType: "number", valueFormatter: (params) => formatAmount(params.value),},
    { headerName: "금액 ~까지", field: "amount_to", editable: true, minWidth: 80, maxWidth: 120, pinned: "left", type: "numericColumn", sortable: true, cellDataType: "number", valueFormatter: (params) => formatAmount(params.value),},
    { headerName: "할증율", field: "add_rate", editable: true, minWidth: 80, maxWidth: 120, type: "numericColumn", sortable: true, cellDataType: "number", valueFormatter: (params) => formatAmount(params.value),},
    { headerName: "할인율", field: "sale_rate", editable: true, minWidth: 80, maxWidth: 120, type: "numericColumn", sortable: true, cellDataType: "number", valueFormatter: (params) => formatAmount(params.value),},
    { headerName: "기금율", field: "fund_rate", editable: true, minWidth: 80, maxWidth: 120, type: "numericColumn", sortable: true, cellDataType: "number", valueFormatter: (params) => formatAmount(params.value),},
    { headerName: "수수료", field: "cms_rate", editable: true, minWidth: 80, maxWidth: 120, type: "numericColumn", sortable: true, cellDataType: "number", valueFormatter: (params) => formatAmount(params.value),},
    { headerName: "입력자", field: "input_user", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "입력시간", field: "input_time", sortable: true, filter: "agDateColumnFilter", minWidth: 170, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value), cellDataType: "date",},
    { headerName: "수정자", field: "update_user", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "수정시간", field: "update_time", sortable: true, filter: "agDateColumnFilter", minWidth: 170, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value), cellDataType: "date",},
  ];
};