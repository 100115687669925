import { atomFamily } from 'recoil';
import { calculateDate } from "../../utils/Formatter";

const id = 'DP001';
export const rowsState = atomFamily({
  key: (tabKey) => `${id}-rowsState${tabKey}`,
  default: []
});

export const loadingState = atomFamily({
  key: (tabKey) => `${id}-loadingState${tabKey}`,
  default: false
});

export const searchConditionsState = atomFamily({
  key: (tabKey) => `${id}-searchConditionsState${tabKey}`,
  default: [    
    { id: 'deposit_date', name: '입금일자', type: 'date', value: calculateDate(-27) },
    { id: 'transaction_no', name: '매출번호', type: 'text', value: '' },
  ]
});
 
export const searchConditionitemsPerRow = 3;