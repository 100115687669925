import React, { useCallback, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { CustomGrid } from "../components/CustomGrid";
import { EditBar, EditBarContent } from "../components/EditBar";
import SearchConditions from "../components/SearchConditions";
import { fetchData } from "../utils/fetchUtils";
import {
  handleConditionChange,
  handleDateRangeChange,
} from "../utils/SearchConditionUtils";
import LoadingOverlay from "../components/LoadingOverlay";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import CustomButton from "../components/CustomButton";
import useSaveData from '../utils/useSaveData';
import { Confirm } from '../components/Confirm';

import { utils } from 'xlsx';
import XLSX from 'xlsx-js-style'; // 변경된 부분


const CommonMenuTemplate1 = ({
  menuId,
  menuName,
  searchConditionsState,
  itemsPerRow,
  searchApi,
  saveApi,
  gridProps,
  keyColumn,
  resetChangedMain,
  isReport,
  handleGridResultMessage
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [currentColumns, ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchConditions, setSearchConditions] = useRecoilState(searchConditionsState);
  const [rowData, setRowData] = useState([]);
  const [sideEditBarOpen, setSideEditBarOpen] = useState(false);
  const [editableRow, setEditableRow] = useState({});
  const [isConfirming, setIsConfirming] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const gridRef = useRef();

  // const onFetchData = useCallback(async () => {
  //   setLoading(true);
  //   const data = await fetchData(searchApi, searchConditions);
  //   setRowData(data);
  //   setLoading(false);
  //   resetChangedMain();
  //   const count = data.length;
  //   handleGridResultMessage(`${count}건 조회 되었습니다.`);
  // }, [searchApi, searchConditions, resetChangedMain,handleGridResultMessage]);

  const onFetchData = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchData(searchApi, searchConditions);
      setRowData(data);
      resetChangedMain();
      const count = data.length;
      handleGridResultMessage(`${count}건 조회 되었습니다.`);
    } catch (error) {
      if (error.message === 'Request failed with status code 401') {
        alert('로그인 페이지로 이동합니다.');
        window.location.href = '/';
      }
      console.error('Error fetching data in onFetchData:', error);
    } finally {
      setLoading(false);
    }
  }, [searchApi, searchConditions, resetChangedMain, handleGridResultMessage]);
  
 
  function formatDateToKoreanFormat(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hour = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');
    
    const period = hour < 12 ? '오전' : '오후';
    const hour12Format = hour <= 12 ? hour : hour - 12;
    
    return `${year}-${month}-${day} ${period} ${String(hour12Format).padStart(2, '0')}:${minutes}:${seconds}`;
  }
  
  const exportExcelFile = useCallback(() => {
    const columns = gridProps.columnDefs;
    const ws = utils.json_to_sheet([], { skipHeader: true });
    const wscols = columns
      .filter(col => !col.hide) // hide 속성이 true인 컬럼 제외
      .map(col => ({ wch: col.maxWidth / 8.5 }));

    // 헤더 설정
    const visibleColumns = columns.filter(col => !col.hide);
    visibleColumns.forEach((column, idx) => {
        const cell_address = { c: idx, r: 0 };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        ws[cell_ref] = {
            t: 's',
            v: column.headerName,
            s: {
              fill: { fgColor: { rgb: "D3D3D3" } }, // 연한 회색 배경
              font: {
                bold: true,
                sz: 9,
                name: "맑은고딕", 
                color: { rgb: "000000" } // 글자색은 검은색으로 지정합니다. 원하시는 다른 색상 코드로 변경 가능합니다.
              },
              alignment: {
                vertical: "center",
                horizontal: "center"
              }
            }
        };
    });

    // 데이터 복사 부분
    for (let R = 0; R !== rowData.length; ++R) {
      visibleColumns.forEach((column, C) => {
          if (rowData[R].hasOwnProperty(column.field)) {
              const cell_address = { c: C, r: R + 1 };
              const cell_ref = XLSX.utils.encode_cell(cell_address);
              let cellValue = rowData[R][column.field];

              // null 값을 공백으로 변환
              if (cellValue === null) {
                  cellValue = "";
              } else if (column.cellDataType === "date") {
                  cellValue = formatDateToKoreanFormat(cellValue);
              }

              if (column.cellDataType === "number" && cellValue !== "") {
                  ws[cell_ref] = {
                      t: 'n',
                      v: cellValue,
                      s: {
                          font: { sz: 9, name: "맑은고딕",  }, // 폰트 크기 10으로 설정
                          numFmt: "#,##0"  // 여기서 숫자 형식을 지정합니다. 쉼표를 포함한 형식입니다.
                      }
                  };
              } else {
                  ws[cell_ref] = {
                      t: 's',
                      v: cellValue,
                      s: {
                          font: { sz: 9, name: "맑은고딕",  } // 폰트 크기 10으로 설정
                      }
                  };
              }
          }
      });
    }



    // 범위 업데이트
    ws["!ref"] = utils.encode_range({ s: { c: 0, r: 0 }, e: { c: visibleColumns.length - 1, r: rowData.length } });

    ws['!cols'] = wscols;

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, menuName+Date.now()+".xlsx");
  }, [rowData, gridProps.columnDefs, menuName]);

  const [saveData] = useSaveData(saveApi, '', onFetchData, '');

  const setRowStatus = useCallback((row, statusCode, statusName) => {
    return {...row, rowStatusCode: statusCode, rowStatusName: statusName};
  }, []);

  const handleAddButtonClick = useCallback(() => {
    const newRow = currentColumns.reduce((obj, column) => {
      obj[column.field] = "";
      return obj;
    }, {});
    setEditableRow(setRowStatus(newRow, 'I', '추가'));
    setSideEditBarOpen(true);
  }, [currentColumns, setRowStatus]);

  const handleCancelEditBar = useCallback(() => {
    setSideEditBarOpen(false);
  }, []);

  const handleEditBarOperation = useCallback(() => {
    if(editableRow.rowStatusCode === 'I'){
      const existingRow = rowData.find(
        (row) => row[keyColumn] === editableRow[keyColumn]
      );
      if (existingRow) {
        alert("중복된 키 값이 있습니다. 다른 값을 입력해주세요.");
        return;
      }
    }
    console.log(editableRow);
    saveData([editableRow], 'main');
    setSideEditBarOpen(false);
  }, [rowData, keyColumn, editableRow, saveData]);
  
  const onGridReady = useCallback(() => {
    if (!rowData || rowData.length < 1) {
      onFetchData();
    }
  }, [rowData, onFetchData]);

  // 일괄삭제 팝업창
  const handleDeleteButtonClick = useCallback(() => {
    let selectedData = [];
    let selectedNodes = gridRef.current.api.selectionService.selectedNodes;
    if(Object.keys(selectedNodes).length > 0){
      for(let i=0;i<Object.keys(selectedNodes).length;i++){
        selectedData.push(selectedNodes[Object.keys(selectedNodes)[i]].data);
      }
    }
    if(selectedData.length > 0){
      setIsConfirming(true);
      setCheckedRows(selectedData);
    }else{
      alert('선택된 데이터가 없습니다.');
    }
  }, [gridRef]);

  // 일괄삭제 삭제처리
  const onConfirmSave = useCallback(() => {     
    const updatedCheckedRows = checkedRows.map(row => setRowStatus(row, 'D', '삭제'));
    console.log(updatedCheckedRows);
    saveData(updatedCheckedRows, 'main');
    setIsConfirming(false);
    onFetchData();
  }, [saveData, checkedRows, onFetchData, setRowStatus]);

  const onConfirmClose = useCallback(() => {
    setIsConfirming(false);
  }, []);

  //수정 창 띄우기
  const onRowEdit = useCallback((data) => {
    setEditableRow(setRowStatus(data, 'U', '수정'));
    setSideEditBarOpen(true);
  }, [setRowStatus]);

  //삭제 창 띄우기
  const onRowDelete = useCallback((data) => {
    setEditableRow(setRowStatus(data, 'D', '삭제'));    
    setSideEditBarOpen(true);
  }, [setRowStatus]);

  const commonGridProps = {
    rowData: rowData,
    setRowData: setRowData,
    columnDefs: gridProps.columnDefs,
    defaultColDef: gridProps.defaultColDef,
    dataTypeDefinitions: gridProps.dataTypeDefinitions,
    onGridReady: gridProps.onGridReady || onGridReady,
    gridRef: gridRef,
    keyColumn: keyColumn,
    onRowEdit: onRowEdit,
    onRowDelete: onRowDelete,
    isReport:isReport
  };

  const renderConfirm = useCallback(() => {
    return isConfirming ?
      <Confirm
        open = {isConfirming}
        titleMessage="삭제 확인"
        detailMessage="선택된 데이터를 모두 삭제 하시겠습니까?"
        handleConfirm={onConfirmSave}
        handleClose={onConfirmClose}
      /> : null;
  }, [isConfirming, onConfirmSave, onConfirmClose]);

  const editBarContent = <EditBarContent 
                              columnDefs={gridProps.columnDefs} 
                              editableRow={editableRow} 
                              setEditableRow={setEditableRow} 
                              handleEditBarOperation={handleEditBarOperation} 
                              handleCancelEditBar={handleCancelEditBar} />;

  return (
    <div style={{ height: "100%" }}>
      <EditBar open={sideEditBarOpen} onClose={handleCancelEditBar} content={editBarContent} />
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{
            border: "1px solid #AAA",
            borderColor: theme.palette.mode === "dark" ? "#555" : "#BBB",
            borderRadius: "5px",
            padding: "1px",
            marginTop: "2px",
            marginBottom: "5px",
          }}
        >
          <div
            style={{
              backgroundColor: theme.palette.mode === "dark" ? colors.primary[700] : "#f5f5f5",
              padding: "10px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <SearchConditions
              searchConditions={searchConditions}
              setSearchConditions={setSearchConditions}
              onConditionChange={(name, value) =>
                handleConditionChange(name, value, setSearchConditions)
              }
              onDateRangeChange={(name, { from, to }) =>
                handleDateRangeChange(name, { from, to }, setSearchConditions)
              }
              onSearch={onFetchData}
              itemsPerRow={itemsPerRow}
            />
          </div>
        </div>
        
        <div className="toolbar">
        {!isReport && <>
          <CustomButton type={"ADD"} text={"신규"} onClick={handleAddButtonClick}/>
          <CustomButton type={"DELETE"} text={"삭제"} onClick={handleDeleteButtonClick}/>
          </>
        }
          <CustomButton type={"SEARCH"} text={"조회"} onClick={onFetchData} />
          <CustomButton type={"SEARCH"} text={"엑셀"} onClick={exportExcelFile} />
        </div>        
        <div style={{ height: "100%", overflow: "auto" }}>
          <CustomGrid gridProps={commonGridProps} />
        </div>        
      </div>
      {loading && <LoadingOverlay />}
      {renderConfirm()}
    </div>
  );
};

export default CommonMenuTemplate1;