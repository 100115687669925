import { atomFamily } from 'recoil';
//import { createSelectObject } from '../../utils/SearchConditionUtils';

const id = 'AD005';
export const rowsState = atomFamily({
  key: (tabKey) => `${id}-rowsState${tabKey}`,
  default: []
});

export const loadingState = atomFamily({
  key: (tabKey) => `${id}-loadingState${tabKey}`,
  default: false
});

export const searchConditionsState = atomFamily({
  key: (tabKey) => `${id}-searchConditionsState${tabKey}`,
  default: [
    { id: 'user_id', name: '아이디', type: 'text', value: '' },
    { id: 'user_name', name: '이름', type: 'text', value: '' },
  ]
});

export const searchConditionitemsPerRow = 4;