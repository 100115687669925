import { atom } from 'recoil';

export const rfidMessageState = atom({
  key: `rfidMessageState`,
  default: []
});

export const barcodeMessageState = atom({
  key: `barcodeMessageState`,
  default: []
});

export const cardMessageState = atom({
  key: `cardMessageState`,
  default: []
});
