import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useAuth } from './auth';  // Import useAuth
import axios from 'axios';  // Import axios
import 'bootstrap';
import './login.css';
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // eslint-disable-next-line no-unused-vars
  const { isLoggedIn, setIsLoggedIn } = useAuth();  // Get setIsLoggedIn from the auth context

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios({
        method: 'post',
        url: 'https://work.jhseo.me:8004/auth/token',
        data: {
          username: username,
          password: password,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      localStorage.setItem('token', response.data.token);
      if(response !== undefined){
        setIsLoggedIn(true);
      }
    } catch (err) {
      console.error(err);
    }
  };
  return ReactDOM.createPortal(
    <div className="login login-v1">
		<div className="login-container">
			<div className="login-header">
				<div className="brand">
					<div className="d-flex align-items-center">
						<span className="logo"></span> <b>Max</b> POS
					</div>
					<small>for 두구화훼단지</small>
				</div>
				<div className="icon">
					<i className="fa fa-lock"></i>
				</div>
			</div>
			<div className="login-body">
				<div className="login-content fs-13px">
					<form onSubmit={handleSubmit}>
						<div className="form-floating mb-20px">
							<input type="text" className="form-control fs-13px h-45px" id="id" placeholder="Id" onChange={handleUsernameChange}/>
							<label htmlFor="Id" className="d-flex align-items-center py-0">아이디</label>
						</div>
						<div className="form-floating mb-20px">
							<input type="password" className="form-control fs-13px h-45px" id="password" placeholder="Password" onChange={handlePasswordChange}/>
							<label htmlFor="password" className="d-flex align-items-center py-0">패스워드</label>
						</div>
						<div className="form-check mb-20px">
							<input className="form-check-input" type="checkbox" value="" id="rememberMe" />
							<label className="form-check-label" htmlFor="rememberMe">
								기억하기
							</label>
						</div>
						<div className="login-buttons">
							<button type="submit" className="btn h-45px btn-theme d-block w-100 btn-lg">로그인</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>,
    document.getElementById('login-root')
  );
};

export default Login;
