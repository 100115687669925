import React, { useState, useRef } from "react";
import { HashRouter as Router } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import SideBar from './components/SideBar';
import TabHeader from './components/TabHeader';
import styled from "@emotion/styled";

import { rfidMessageState, barcodeMessageState, cardMessageState } from './AppState';

import AD001 from './menus/AD001/AD001';
import AD002 from './menus/AD002/AD002';
import AD003 from './menus/AD003/AD003';
import AD005 from './menus/AD005/AD005';
import US001 from './menus/US001/US001';
import SA001 from './menus/SA001/SA001';
import SA002 from './menus/SA002/SA002';
import SA003 from './menus/SA003/SA003';
import DP001 from './menus/DP001/DP001';
import CF001 from './menus/CF001/CF001';
import CF002 from './menus/CF002/CF002';
import CF003 from './menus/CF003/CF003';
import CF004 from './menus/CF004/CF004';
import WD001 from './menus/WD001/WD001';
import ColumnConfigurator from './menus/ColumnConfigurator';


// const AD001 = React.lazy(() => import("./menus/AD001/AD001"));
// const AD002 = React.lazy(() => import("./menus/AD002/AD002"));
// const AD003 = React.lazy(() => import("./menus/AD003/AD003"));
// const US001 = React.lazy(() => import("./menus/US001/US001"));
// const SA001 = React.lazy(() => import("./menus/SA001/SA001"));
// const SA002 = React.lazy(() => import("./menus/SA002/SA002"));
// const SA003 = React.lazy(() => import("./menus/SA003/SA003"));


const menuComponents = {
  'AD001': AD001,
  'AD002': AD002,
  'AD003': AD003,
  'AD005': AD005,
  'US001': US001,
  'SA001': SA001,
  'SA002': SA002,
  'SA003': SA003,
  'CF001': CF001,
  'CF002': CF002,
  'CF003': CF003,
  'CF004': CF004,
  'DP001': DP001,
  'WD001': WD001,
  'ColumnConfigurator': ColumnConfigurator,
  
};

const TabContent = styled.div`
  overflow: auto;
  height: calc(100vh - 30px);
`;

const Footer = styled.div`
  font-size: 8pt;
`;

function App() {
  const [theme, colorMode] = useMode();

  const [, setRfidMessage] = useRecoilState(rfidMessageState);
  const [, setBarcodeMessage] = useRecoilState(barcodeMessageState);
  const [, setCardMessage] = useRecoilState(cardMessageState);

  const [gridResultMessage, setGridResultMessage] = useState('');

  const handleGridResultMessage = (message) => {
    setGridResultMessage(message);
  };

  const [showSidebar, setWhowSidebar] = useState(true);

  window.changeTheme = (newTheme) => {
    colorMode.toggleColorMode();
  };
  
  window.showSidebar = () => {
    setWhowSidebar(!showSidebar);
  }; 

  window.receiveRfidMessage = (message) => {
    setRfidMessage(message);
  };
  window.receiveCardMessage = (message) => {
    setCardMessage(message);
  };
  window.receiveBarcodeMessage = (message) => {
    setBarcodeMessage(message);
    const newTab = {
      'id':12,
      'menu_id':"AD002",
      'menu_name':"코드관리",
      'odr':1,
      'parent_menu':"ADMIN",
      'path': "/AD002"
    };
    const tabExist = tabs.some( tab => tab.menu_name === newTab.menu_name );
    if ( ! tabExist ) {
      addTab(newTab);
    }else{
      setCurrentTabWithWarning(newTab);
    }
  };

  const nextTabKey = useRef(1);

  const [tabs, setTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState({ key: nextTabKey.current, menu_name: '', menu_id: '' });

  const [tabsChanged, setTabsChanged] = useState({main: {}, sub: {}});

  const handleTabChange = (tabKey, gridType) => {
    setTabsChanged(prev => ({...prev, [gridType]: {...prev[gridType], [tabKey]: true}}));
  }

  const resetChanged = (tabKey, gridType) => {
    setTabsChanged(prev => ({...prev, [gridType]: {...prev[gridType], [tabKey]: false}}));
  }

  const addTab = (newTab) => {
    setGridResultMessage('');
    if (currentTab === undefined) {
      console.error("currentTab is undefined");
      return;
    }
    
    if (tabsChanged.main[currentTab.key] || tabsChanged.sub[currentTab.key]) {
      if (window.confirm("변경된 데이터가 있습니다. 변경사항을 초기화하겠습니까?")) {
        setTabsChanged({main: {}, sub: {}});
        const tabExist = tabs.some ( tab => tab.menu_name === newTab.menu_name );
    
        if ( ! tabExist ) {
          const tabToAdd = { ...newTab, key: nextTabKey.current++ };
          setTabs((prevTabs) => [...prevTabs, tabToAdd ]);
          setCurrentTab( tabToAdd );
        }
      }
    } else {
      const tabExist = tabs.some( tab => tab.menu_name === newTab.menu_name );
    
      if ( ! tabExist ) {
        const tabToAdd = { ...newTab, key: nextTabKey.current++ };
        setTabs(( prevTabs ) => [...prevTabs, tabToAdd ]);
        setCurrentTab( tabToAdd );
      }
    }
  };
  
  
  const setCurrentTabWithWarning = (tab) => {
    if (currentTab !== tab && (tabsChanged.main[currentTab?.key] || tabsChanged.sub[currentTab?.key])) {
      if (window.confirm("변경된 데이터가 있습니다. 변경사항을 초기화하겠습니까?")) {
        setTabsChanged({main: {}, sub: {}});
        setCurrentTab(tab);
      }
    } else {
      setCurrentTab(tab);
    }
  };
  
  const handleTabClose = (tabToClose) => {
    // Get the index of the tab to be closed
    const closingTabIndex = tabs.findIndex((tab) => tab === tabToClose);
    
    // Remove the tab
    const newTabs = tabs.filter((tab) => tab !== tabToClose);
    setTabs(newTabs);
  
    // If the tab to be closed is the current tab, update the current tab to be the previous one
    if (currentTab === tabToClose && closingTabIndex > 0) {
      setCurrentTab(newTabs[closingTabIndex - 1]);
    } else if (currentTab === tabToClose && newTabs.length > 0) {
      // If the tab to be closed is the first tab, update the current tab to be the first one in the new tabs
      setCurrentTab(newTabs[0]);
    } 
  };

  return (
      <Router>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {showSidebar?<SideBar openTabs={tabs} addTab={addTab} setTab={setCurrentTabWithWarning} />:null}
            <main className='AppMain'>
              <TabHeader tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTabWithWarning} handleTabClose={handleTabClose} />
              <TabContent>
                {tabs.length > 0 ? (
                  <>
                    {menuComponents.hasOwnProperty(currentTab.menu_id) ? (
                      React.createElement(menuComponents[currentTab.menu_id], {
                        tab: currentTab,
                        onChangeMain: () => handleTabChange(currentTab.key, 'main'),
                        onChangeSub: () => handleTabChange(currentTab.key, 'sub'),
                        resetChangedMain: () => resetChanged(currentTab.key, 'main'),
                        resetChangedSub: () => resetChanged(currentTab.key, 'sub'),
                        handleGridResultMessage: handleGridResultMessage
                      })
                    ) : (
                      <div>
                        경고 : 해당 메뉴에 해당하는 컴포넌트가 정의되지 않았습니다! - [{currentTab.menu_id}]{currentTab.menu_name}
                      </div>
                    )}
                  </>
                ) : null}
              </TabContent>
              <Footer>
                <div style={{float: 'left', width: '30%'}} >&copy; 2023 Max POS - Jh Solutions All Rights Reserved</div>
                <div style={{textAlign: 'right', float: 'left', width: '70%'}}>[{currentTab.menu_id}] {gridResultMessage}</div>
              </Footer>
            </main>
            <div id="app-loaded"></div>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </Router>
  );
}

export default App;