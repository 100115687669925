import { formatAMPM } from "../../utils/Formatter";
// eslint-disable-next-line no-unused-vars
import { createSelectObject } from '../../utils/agGridColumnUtils';

export const getMainColumns = () => {
  return [
    { field: "menu_id", headerName: "메뉴ID", edit: true, minWidth: 30, maxWidth: 120, headerCheckboxSelection: true, checkboxSelection: true,},
    { field: "menu_name", headerName: "메뉴명", edit: true, minWidth: 30, maxWidth: 200,},
    { field: "odr", headerName: "순번", edit: true, minWidth: 30, maxWidth: 80,},
    { field: "updated_by", headerName: "수정자", minWidth: 30, maxWidth: 100,},
    { field: "updated_at", headerName: "수정시간", minWidth: 100, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value),},
  ];
};

export const getSubColumns = () => {
  return [
    { field: "menu_id", headerName: "메뉴ID", edit: true, minWidth: 30, maxWidth: 120, headerCheckboxSelection: true, checkboxSelection: true,},
    { field: "menu_name", headerName: "메뉴명", edit: true, minWidth: 30, maxWidth: 200,},
    { field: "odr", headerName: "순번", edit: true, minWidth: 30, maxWidth: 80,},
    { field: "path", headerName: "경로", edit: true, minWidth: 30, maxWidth: 100,},
    { field: "created_by", headerName: "입력자", minWidth: 30, maxWidth: 100,},
    { field: "created_at", headerName: "입력시간", minWidth: 100, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value),},
    { field: "updated_by", headerName: "수정자", minWidth: 30, maxWidth: 100,},
    { field: "updated_at", headerName: "수정시간", minWidth: 100, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value),},
    { field: "parent_menu", headerName: "상위메뉴", hide: true, minWidth: 30, maxWidth: 200,},
  ];
};

