import React, { useState } from 'react';
import { Button, TextareaAutosize, TextField  } from '@mui/material';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import axios from 'axios';
import { useTheme } from "@mui/material";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
//import NumericCellEditor from '../components/ag-grid/numericCellEditor.jsx';

const defaultRow = {
    field: 'id',
    headerName: '아이디',    
    hide: false,
    maxWidth: 120,
    minWidth: 100,
    pinned: '',     //고정표시 여부, left/right
    edit: false,
    sideBarEditType: '',
    use_code: false,
    code: '',
    cellDataType: '',   //데이타 타입 'text', 'number', 'boolean', 'date', 'dateString', 'object'
    type: '',           //타입 numericColumn, rightAligned
    checkboxSelection: false,
    headerCheckboxSelection: false,
    filter: '',         // 컬럼 표시에 사용할 필터      agDateColumnFilter  
    sortable: true,
    valueFormatter: '',  // 포매팅 함수 (params) => formatAMPM(params.value)
    editable: false,
    cellSpan: false,
};

const defaultRowText = {
    headerName: '표시명',
    field: '매칭컬럼',
    hide: '숨김',
    maxWidth: '최대길이',
    minWidth: '최소길이',
    pinned: '고정표시',
    edit: '수정',
    sideBarEditType: '수정타입',
    use_code: '코드',
    code: '공통코드',
    cellDataType: '셀타입',
    type: '셀표시',
    checkboxSelection: '체크',
    headerCheckboxSelection: '헤더체크',
    filter: '필터명',
    sortable: '정렬',
    valueFormatter: '포매팅',
    editable: '그리드수정',
    cellSpan: '셀합침',
};

const predefinedRowsData = [
    { headerName: '입력자', field: 'input_user', sortable: true, minWidth: 80, maxWidth: 120 },
    { headerName: '입력시간', field: 'input_time', sortable: true, cellDataType:'date', filter: 'agDateColumnFilter', valueFormatter: '"(params) => formatAMPM(params.value)"', minWidth: 170, maxWidth: 200 },
    { headerName: '수정자', field: 'update_user', sortable: true , minWidth: 80, maxWidth: 120},
    { headerName: '수정시간', field: 'update_time', sortable: true, cellDataType:'date', filter: 'agDateColumnFilter', valueFormatter: '"(params) => formatAMPM(params.value)"', minWidth: 170, maxWidth: 200 }
];

const predefinedRows = predefinedRowsData.map(rowData => ({ ...defaultRow, ...rowData }));

const pinnedOptions = ['', 'left', 'right'];
const sideBarEditTypeOptions = ['text', 'number', 'date', 'select', 'checkbox'];
const cellDataTypeOptions = ['', 'text', 'number', 'date', 'dateString', 'select', 'checkbox'];
const typeOptions = ['', 'numericColumn', 'rightAligned'];
const filterOptions = ['', 'agDateColumnFilter'];
const valueFormatterOptions = ['', '"(params) => formatAMPM(params.value)"','"(params) => formatAmount(params.value)"'];

const mapDatabaseTypeToCellDataType = (dbType) => {
    switch (dbType) {
        case 'character varying':
        case 'varchar':
        case 'text':
            return 'text';
        case 'timestamp with time zone':
        case 'timestamp':
        case 'date':
            return 'date';
        case 'integer':
        case 'smallint':
        case 'bigint':
        case 'decimal':
        case 'numeric':
        case 'double':
        case 'double precision':
            return 'number';
        case 'boolean':
            return 'checkbox';
        default:
            return dbType;  // 기본값 또는 다른 적절한 처리
    }
};

const ColumnConfigurator = () => {
    const [rows, setRows] = useState([defaultRow]);
    const [codeOutput, setCodeOutput] = useState('');
    const [configText, setConfigText] = useState('');
    const [tableText, setTableText] = useState('');

    const handleConfigTextChange = (event) => {
        setConfigText(event.target.value);
    };

    const handleCTableTextChange = (event) => {
        setTableText(event.target.value);
    };

    // const handleCellDataTypeChange = (rowIndex, newType) => {
    //     const updatedRows = [...rows];
    //     updatedRows[rowIndex].cellDataType = newType;
    
    //     if (newType === 'number') {
    //         updatedRows[rowIndex].type = 'numericColumn';
    //         updatedRows[rowIndex].valueFormatter = '"(params) => formatAmount(params.value)"';
    //     }
    //     if (newType === 'date') {
    //         updatedRows[rowIndex].valueFormatter = '"(params) => formatAMPM(params.value)"';
    //     }
    
    //     setRows(updatedRows);
    // };
    

    const loadRowsFromSourceCode = () => {
        try {
            const matchResult = configText.match(/return (\[.*\]);/s);
            if (!matchResult) {
                alert("유효한 소스 코드가 아닙니다.");
                return;
            }
            let rowDataString = matchResult[1];            

            // ...createSelectOption('XX') 형식의 텍스트 제거
            rowDataString = rowDataString.replace(/,\s*\.\.\.createSelectOption\('.*?'\)/g, '');
            rowDataString = rowDataString.replace(/\{'cell-span': getDefaultCellSpanRule\}/g, '1');
            rowDataString = rowDataString.replace(/getDefaultRowSpan/g, '1');

            // eslint-disable-next-line no-eval
            const rowData = eval(rowDataString);  // 문자열을 JavaScript 배열로 변환
            
            const updatedRows = rowData.map((row) => {
                
                delete row.rowSpan;
                delete row.cellClassRules;
                delete row.getDefaultRowSpan;

                for (let [key, defaultValue] of Object.entries(defaultRow)) {
                    if (row[key] === undefined) {
                        row[key] = defaultValue;
                    }
                    if (key === 'valueFormatter' && row[key]) {
                        row[key] = `"${row[key]}"`;
                    }
                    // 자동으로 cellDataType이 'number'인 경우 valueFormatter 설정
                    if (row.cellDataType === 'number') {
                        row.valueFormatter = '"(params) => formatAmount(params.value)"';
                    }
                    if (row.cellDataType === 'date') {
                        row.valueFormatter = '"(params) => formatAMPM(params.value)"';
                    }                    
                }
                return row;
            });
    
            setRows(updatedRows);
        } catch (error) {
            alert("소스 코드 파싱 중 에러가 발생했습니다.");
            alert(error);
        }
    };
    
    

    const addRowFromPreDefined = () =>{
        setRows(prevRows => [...prevRows, ...predefinedRows]);
    }

    const addRowFromInputText = () => {
        const columns = configText.split('\n').filter(text => text.trim() !== '');  // 입력된 텍스트를 줄바꿈 기준으로 나누고 빈 줄 제거
        const newRows = columns.map((columnText, index) => {
            const [fieldValue, headerValue] = columnText.split(":").map(text => text.trim());
            const newRow = {
                ...defaultRow,
                field: fieldValue,
                headerName: headerValue
            };
            return newRow;
        });
        setRows(prevRows => [...prevRows, ...newRows]);
    };
    const theme = useTheme();
    
    // ActionCellRenderer 내에서 클릭 이벤트 핸들러를 정의
    const ActionCellRenderer = (params) => {
        const handleDuplicate = () => {
            const rowIndex = params.rowIndex;
            const duplicatedRow = { ...rows[rowIndex] };
            const updatedRows = [...rows];
            updatedRows.splice(rowIndex, 0, duplicatedRow);
            setRows(updatedRows);
        };

        const handleDelete = () => {
            const rowIndex = params.rowIndex;
            const updatedRows = [...rows];
            updatedRows.splice(rowIndex, 1);
            setRows(updatedRows);
        };

        return (
            <span>
                <Button size="small" color={theme.palette.mode === "dark"?"secondary":"primary"} startIcon={<EditIcon style={{paddingLeft:'0px',paddingBottom:'3px'}} size="small" fontSize="inherit"/>} onClick={handleDuplicate}>
                    복제
                </Button>
                <Button size="small" color={theme.palette.mode === "dark"?"secondary":"primary"} startIcon={<DeleteIcon style={{paddingBottom:'3px'}} size="small" fontSize="inherit"/>} onClick={handleDelete}>
                    삭제
                </Button>
            </span>
        );
    };


    const action_column = {
        field: 'action',
        headerName: 'ACTION',
        cellRenderer: ActionCellRenderer,
        minWidth: 155,
        maxWidth: 165,
        pinned: 'left'      
      };
    const gridColumns = [action_column,...Object.keys(defaultRow).map(key => {
        let width = 120;
        let cellEditor;
        let cellEditorParams;

        const booleanFields = [
            'hide', 'edit', 'use_code', 'checkboxSelection', 'headerCheckboxSelection', 'sortable', 'editable'
        ];
        if (key === 'headerName' || key === 'field' || key === 'valueFormatter') {
            width = 150;
        } else if (booleanFields.includes(key)) {
            width = 80;
        } else if (key === 'maxWidth' || key === 'minWidth' || key === 'pinned' || key === 'sideBarEditType' || key === 'cellDataType' || key === 'type') {
            width = 80;
        }
        if (key === 'pinned') {
            cellEditor = 'agSelectCellEditor';
            cellEditorParams = { values: pinnedOptions };
        } else if (key === 'sideBarEditType') {
            cellEditor = 'agSelectCellEditor';
            cellEditorParams = { values: sideBarEditTypeOptions };
        } else if (key === 'cellDataType') {
            cellEditor = 'agSelectCellEditor';
            cellEditorParams = { values: cellDataTypeOptions };
        } else if (key === 'type') {
            cellEditor = 'agSelectCellEditor';
            cellEditorParams = { values: typeOptions };
        } else if (key === 'filter') {
            cellEditor = 'agSelectCellEditor';
            cellEditorParams = { values: filterOptions };
        } else if (key === 'valueFormatter') {
            cellEditor = 'agSelectCellEditor';
            cellEditorParams = { values: valueFormatterOptions };
        }

        return {
            headerName: defaultRowText[key],
            field: key,
            width: width,
            editable: true,
            cellEditor,
            cellEditorParams,
            cellStyle: { 'white-space': 'normal' }
        };
    })];

    const resetAll = () => {
        setRows([defaultRow]);
        setCodeOutput('');
        setConfigText('');
    };

    const addRow = () => {
        setRows(prevRows => [...prevRows, { ...defaultRow }]);
    };

    const generateCode = () => {
        let code = 'export const getColumns = () => {\n  return [';
        rows.forEach(row => {
            code += '\n    {';
            for (let [key, value] of Object.entries(row)) {
                // 속성 값이 공백인 경우 제외
                if (value === '') continue;
    
                if (key === 'valueFormatter' && typeof value === 'string') {
                    code += ` ${key}: ${value.replace(/^"(.+)"$/, "$1")},`; // 큰따옴표 제거
                } else if (typeof value === 'boolean' && value) {
                    code += ` ${key}: ${value},`;
                } else if (typeof value === 'string' && value) {
                    code += ` ${key}: "${value}",`;
                } else if (typeof value === 'number') {
                    code += ` ${key}: ${value},`;
                }
            }

            // 수정타입이 'select'이고 코드를 사용 중일 경우 ...createSelectOption('CODE') 추가
            if (row.sideBarEditType === 'select' && row.use_code) {
                code += ` ...createSelectOption('${row.code}'),`;
            }

            if (row.cellSpan) {
                code += ` rowSpan: getDefaultRowSpan, cellClassRules: {'cell-span': getDefaultCellSpanRule},`;
            }

            code += '},';
        });
        code += '\n  ];\n};';
        setCodeOutput(code);

        // 클립보드에 복사
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(code)
                .then(() => {
                    alert('코드가 클립보드에 복사되었습니다.');
                })
                .catch(err => {
                    alert('클립보드에 복사하지 못했습니다.', err);
                });
        } else {
            alert('이 브라우저는 클립보드 기능을 지원하지 않습니다.');
        }
    };

    const loadColumnsFromDatabase = async () => {
        try {
            const response = await axios.get(`https://work.jhseo.me:8004/config/getColumns?table_name=${configText}`);
            const databaseColumns = response.data;
    
            const newRows = databaseColumns.map(column => {
                const cellDataType = mapDatabaseTypeToCellDataType(column.dataType);
                return {
                    ...defaultRow,
                    field: column.columnName,
                    headerName: column.columnName,
                    cellDataType: cellDataType,
                    type: cellDataType === 'number' ? 'numericColumn' : '',
                    valueFormatter: (cellDataType === 'number') ? '"(params) => formatAmount(params.value)"' : ( (cellDataType === 'date') ? '"(params) => formatAMPM(params.value)"' : '' ),
                };
            });
    
            setRows(newRows);
    
        } catch (error) {
            console.error(error);
            alert("데이터베이스에서 컬럼 정보를 가져오는 중 에러가 발생했습니다.");
        }
    };
    

    const saveGridPropertiesToDB = async () => {
        try {
            // 여기서는 POST 메서드를 사용하고, 그리드의 속성을 JSON 형식으로 전송한다고 가정합니다.
            console.log(rows);
            const response = await axios.post(`https://code.jhseo.me:1443/config/saveColumns/${tableText}`, rows);
    
            if (response.status === 200) {
                alert("그리드의 속성이 성공적으로 저장되었습니다.");
            } else {
                alert("그리드의 속성을 저장하는 데 문제가 발생했습니다.");
            }
        } catch (error) {
            alert("API 호출 중 에러가 발생했습니다.");
        }
    };
    
    return (
        <div>
            <div style={{ margin: '5px' }}>
                <div>
                    <TextareaAutosize
                        rowsMin={6}
                        placeholder="테이블명,컬럼명...,컬럼소스 export const getColumns = () => { ... }"
                        value={configText}
                        onChange={handleConfigTextChange}
                        style={{ width: '100%', marginBottom: '2px', fontSize: 12, }}
                    />
                    <TextareaAutosize
                        rowsMin={6}
                        placeholder="생성된 코드"
                        value={codeOutput}
                        readOnly={true}
                        onChange={handleConfigTextChange}
                        style={{ width: '100%', marginBottom: '2px', fontSize: 12, }}
                    />
                </div>
                <Button variant="contained" onClick={loadRowsFromSourceCode} style={{ marginLeft: '5px' }}>소스로 행 불러오기</Button>
                <Button variant="contained" onClick={loadColumnsFromDatabase} style={{ marginLeft: '5px' }}>DB 컬럼 불러오기</Button>
                <Button variant="contained" onClick={addRowFromInputText} style={{ marginLeft: '5px' }}>텍스트로 행 추가</Button>                
                <Button variant="contained" onClick={generateCode} style={{ marginLeft: '5px' }}>코드 생성</Button>

                <Button variant="contained" onClick={addRow} style={{ marginLeft: '50px' }}>빈 행 추가</Button>
                <Button variant="contained" onClick={addRowFromPreDefined} style={{ marginLeft: '5px' }}>수정관련 행 추가</Button>

                <Button variant="contained" onClick={resetAll} style={{ marginLeft: '50px' }}>전체 초기화</Button>

                <Button variant="contained" onClick={saveGridPropertiesToDB} style={{ marginLeft: '50px', marginRight: '5px' }}>그리드 속성 DB에 저장</Button>
                <TextField
                    placeholder="저장테이블명"
                    value={tableText}
                    onChange={handleCTableTextChange}
                    inputProps={{
                        style: {
                          height: "2px",
                          fontSize: 12,
                        },
                      }}
                />
            </div>
            <div className="ag-theme-balham" style={{ height: '500px', width: '100%' }}>
                <AgGridReact
                    columnDefs={gridColumns}
                    rowData={rows}
                    singleClickEdit={true}
                    onCellValueChanged={(params) => {
                        const rowIndex = params.rowIndex;
                        let updatedData = { ...rows[rowIndex], [params.colDef.field]: params.newValue };
                        updatedData = { ...rows[rowIndex], [params.colDef.field]: params.newValue };
                        
                        if (params.colDef.field === 'cellDataType' && params.newValue === 'number') {
                            updatedData.type = 'numericColumn';
                            updatedData.valueFormatter = '"(params) => formatAmount(params.value)"';
                        }
                        if (params.colDef.field === 'cellDataType' && params.newValue === 'date') {
                            updatedData.valueFormatter = '"(params) => formatAMPM(params.value)"';
                        }                        
                        
                        const updatedRows = [...rows];
                        updatedRows[rowIndex] = updatedData;
                        setRows(updatedRows);
                    }}
                />
            </div>            
        </div>
    );
};

export default ColumnConfigurator;