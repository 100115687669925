import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { CSSTransition } from 'react-transition-group';
import { Typography } from "@material-ui/core";

import { Select, MenuItem, FormControlLabel,  Checkbox,  InputLabel, FormControl } from "@mui/material";
import CustomButton from "../components/CustomButton";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles({
  drawer: {
    width: 340,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 340,
  },
  // New styles for the transition
  enter: {
    transform: 'translateX(100%)'
  },
  enterActive: {
    transform: 'translateX(0)',
    transition: 'transform 50ms'
  },
  exit: {
    transform: 'translateX(0)'
  },
  exitActive: {
    transform: 'translateX(100%)',
    transition: 'transform 50ms'
  },
});

export const EditBar = ({ open, onClose, content }) => {
  const classes = useStyles();

  return (
    <CSSTransition
      in={open}
      timeout={200}
      classNames={{
        enter: classes.enter,
        enterActive: classes.enterActive,
        //exit: classes.exit,
        //exitActive: classes.exitActive,
      }}
      unmountOnExit
    >
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={onClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {content}
      </Drawer>
    </CSSTransition>
  );
};

const RenderField = ({columnDef, editableRow, setEditableRow}) => {
  const handleChange = (e) => {
    if (columnDef.sideBarEditType === 'checkbox') {
      setEditableRow({...editableRow, [columnDef.field]: e.target.checked});
    } else {
      setEditableRow({...editableRow, [columnDef.field]: e.target.value});
    }
  };

  switch(columnDef.sideBarEditType) {
    case 'date':
      return (
        <div style={{paddingTop:'10px'}}>
          <TextField 
            type="date" 
            key={columnDef.field}
            label={columnDef.headerName}
            id={columnDef.field}
            InputLabelProps={{ shrink: true }}
            value={editableRow[columnDef.field]}
            onChange={handleChange}
            fullWidth
          />
        </div>
      );
    case 'number':
      return (
        <div style={{paddingTop:'10px'}}>
          <TextField
            type="number"
            key={columnDef.field}
            name={columnDef.field}
            label={columnDef.headerName}
            value={editableRow[columnDef.field]}
            margin="normal"
            fullWidth
            onChange={handleChange}
          />
        </div>
      );
    case 'select':
      return (
        <div style={{paddingTop:'20px'}}>
          <FormControl fullWidth>
            <InputLabel variant="outlined" shrink={true}>{columnDef.headerName}</InputLabel>
            <Select
              key={columnDef.field}
              name={columnDef.field}
              label={columnDef.headerName}
              margin="normal"
              value={editableRow[columnDef.field]}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              // sx={{
              //   "& fieldset": {
              //     border: "none",
              //   },
              // }}
            >
              {columnDef.options.map((option, index) => (
                  <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      );
    case 'checkbox':
      return (
        <div style={{paddingTop:'10px'}}>
          <FormControlLabel
            control={
              <Checkbox
                key={columnDef.field}
                name={columnDef.field}
                checked={editableRow[columnDef.field]}
                fullWidth
                onChange={handleChange}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }              
            label={<Typography variant="button" align="left">{columnDef.headerName}</Typography>}
            labelPlacement="start"
          />
        </div>
      );
    default:
      return (
        <div style={{paddingTop:'10px'}}>
          <TextField
            key={columnDef.field}
            name={columnDef.field}
            label={columnDef.headerName}
            value={editableRow[columnDef.field]}
            margin="normal"
            fullWidth
            onChange={handleChange}
          />
        </div>
      );
  }
};

// SideEditBarContent 컴포넌트
export const EditBarContent = ({columnDefs, editableRow, setEditableRow, handleEditBarOperation, handleCancelEditBar}) => {
  return (
    <div style={{padding: "10px"}}>
      <form>
      {columnDefs.map((columnDef) =>
          columnDef.edit ? <RenderField columnDef={columnDef} editableRow={editableRow} setEditableRow={setEditableRow}/> : null
        )}
        <CustomButton type={"SAVE"} 
                      text={editableRow.rowStatusCode==='I'?"추가":editableRow.rowStatusCode==='U'?"변경":"삭제"} 
                      onClick={handleEditBarOperation}/>
        &nbsp;
        <CustomButton type={"SAVE"} text={"취소"} onClick={handleCancelEditBar}/>
      </form>
    </div>
  );
};
