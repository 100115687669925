import React, { useState, useEffect, useCallback } from 'react';
import CommonMenuTemplate2 from '../CommonMenuTemplate2';
import { getMainColumns, getSubColumns } from './AD002Columns';
import { searchConditionsState, searchConditionitemsPerRow } from './AD002State';
import { useRecoilState } from 'recoil';
//import { atomFamily } from 'recoil';
import { barcodeMessageState } from '../../AppState';
import {
  handleConditionChange,
} from "../../utils/SearchConditionUtils";

const AD002 = ({ tab, onChangeMain, onChangeSub, resetChangedMain, resetChangedSub, handleGridResultMessage }) => {
  const [mainColumnDefs] = useState(getMainColumns);
  const [subColumnDefs] = useState(getSubColumns);

  const [barcodeMessage, setBarcodeMessage] = useRecoilState(barcodeMessageState);
  //const aa = searchConditionsState(tab.id,'',barcodeMessage);
  const [searchConditions, setSearchConditions] = useRecoilState(searchConditionsState(tab.id));
  const [message1, setMessage1] = useState(null);

  const processBarcode = useCallback((message) => {
    setMessage1(message);
  }, [setMessage1]);

  useEffect(() => {
    if (message1 && message1.length>0) {
      //alert(message1);
      handleConditionChange("코드", message1, setSearchConditions);
    }
  }, [message1,setSearchConditions]);

  useEffect(() => {
    if (barcodeMessage && barcodeMessage.length>0) {
      processBarcode(barcodeMessage);
      
      //handleConditionChange("common_code", barcodeMessage, setSearchConditions);
      //console.log(searchConditions);
      setBarcodeMessage(null);
    }
  }, [barcodeMessage,setBarcodeMessage, processBarcode]);

  const menuMainGridProps = {
    columnDefs: mainColumnDefs,
  };

  const menuSubGridProps = {
    columnDefs: subColumnDefs,
  };

  return (
      <CommonMenuTemplate2
        menuId={tab.menu_id}
        menuName={tab.menu_name}
        searchConditionsState={searchConditionsState(tab.id)}
        itemsPerRow={searchConditionitemsPerRow}
        searchMainApi={'code/common_code'}
        searchSubApi={'code/common_code_detail'}
        saveMainApi={'code/common_code'}
        saveSubApi={'code/common_code_detail'}
        mainGridProps={menuMainGridProps}
        subGridProps={menuSubGridProps}
        mainGridWidth={"35%"}
        subGridWidth={"65%"}
        mainKeyColumn={'common_code'}
        subKeyColumn={'detail_code'}
        subKeyHiddenSetColumn={'common_code'}
        useModal={false}
        onChangeMain={onChangeMain}
        onChangeSub={onChangeSub}
        resetChangedMain={resetChangedMain}
        resetChangedSub={resetChangedSub}
        isReport={false}
        handleGridResultMessage={handleGridResultMessage}
      />
  );
};

export default AD002;
