import React from 'react';
import { createPortal } from 'react-dom';
import { SyncLoader } from 'react-spinners';
import { useTheme } from "@mui/material";

const LoadingOverlay = () =>
  createPortal(
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.25)', // Semi-transparent background
        zIndex: 1000, // Ensure the loading overlay is in front
        opacity: 0,
        animation: 'fadeIn 0.3s forwards', // Add this
      }}
    >
      <div
        style={{
          backgroundColor: useTheme().palette.mode === "dark"?'black':'white',
          borderRadius: '8px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '20%',
          height: '10%',
        }}
      >
        <SyncLoader color={'#36D7B7'} loading={true} size={15} />
        <p style={{ marginTop: '20px' }}>처리 중입니다...</p>
      </div>
    </div>,
    document.body
  );

// Don't forget to define the animation
const style = document.createElement('style');
style.textContent = `
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;
document.head.append(style);

export default LoadingOverlay;
