// eslint-disable-next-line no-unused-vars
import { formatAMPM, formatAmount } from "../../utils/Formatter";
// eslint-disable-next-line no-unused-vars
import { createSelectObject, createSelectOption } from '../../utils/agGridColumnUtils';

export const getColumns = () => {
  return [
    { headerName: "매출일자", field: "sales_date", sortable: true, minWidth: 80, maxWidth: 120, pinned: "left",},
    { headerName: "매출번호", field: "transaction_no", sortable: true, editable: true, minWidth: 100, maxWidth: 120, pinned: "left",},
    { headerName: "고객유형", field: "merchant_type", sortable: true, minWidth: 80, maxWidth: 90,},
    { headerName: "고객ID", field: "merchant", sortable: true, minWidth: 80, maxWidth: 100,},
    { headerName: "고객명", field: "merchant_name", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "매출액", field: "sale_amount", editable: true, minWidth: 80, maxWidth: 120, type: "numericColumn", sortable: true, cellDataType: "number", valueFormatter: (params) => formatAmount(params.value),},
    { headerName: "기금액", field: "fund_amount", minWidth: 80, maxWidth: 120, type: "numericColumn", sortable: true, cellDataType: "number", valueFormatter: (params) => formatAmount(params.value),},
    { headerName: "잉여금", field: "remaining_amount", minWidth: 80, maxWidth: 120, type: "numericColumn", sortable: true, cellDataType: "number", valueFormatter: (params) => formatAmount(params.value),},
    { headerName: "입금액", field: "bank_deposit", minWidth: 80, maxWidth: 120, type: "numericColumn", sortable: true, cellDataType: "number", valueFormatter: (params) => formatAmount(params.value),},
    { headerName: "수수료", field: "fee", sortable: true, minWidth: 80, maxWidth: 120, type: "numericColumn", cellDataType: "number", valueFormatter: (params) => formatAmount(params.value),},
    { headerName: "메모", field: "memo", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "입력자", field: "input_user", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "입력시간", field: "input_time", sortable: true, filter: "agDateColumnFilter", minWidth: 170, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value), cellDataType: "date",},
    { headerName: "수정자", field: "update_user", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "수정시간", field: "update_time", sortable: true, filter: "agDateColumnFilter", minWidth: 170, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value), cellDataType: "date",},
  ];
};
