import Button from '@mui/material/Button';

const CustomButton = ({type, text, onClick}) => {
    if(type === 'ADD'){
        return <Button variant="contained" color="success" onClick={onClick}>{text}</Button>;
    }else if(type === 'SAVE'){
        return <Button variant="contained" color="success" onClick={onClick}>{text}</Button>;
    }else if(type === 'SEARCH'){
        return <Button variant="contained" color="info" onClick={onClick}>{text}</Button>;
    }else{
        return <Button variant="contained" color="neutral" onClick={onClick}>{text}</Button>;
    }
}
export default CustomButton;