// eslint-disable-next-line no-unused-vars
import { formatAMPM, formatAmount } from "../../utils/Formatter";
// eslint-disable-next-line no-unused-vars
import { createSelectObject, createSelectOption } from '../../utils/agGridColumnUtils';

export const getMainColumns = () => {
  return [
    { headerName: "매출일자", field: "sales_date", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "매출번호", field: "transaction_no", sortable: true, editable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "고객유형", field: "merchant_type", sortable: true, minWidth: 80, maxWidth: 90,},
    { headerName: "고객ID", field: "merchant", sortable: true, minWidth: 80, maxWidth: 100,},
    { headerName: "고객명", field: "merchant_name", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "매출액", field: "sale_amount", editable: true, minWidth: 80, maxWidth: 120, type: "numericColumn", sortable: true,},
    { headerName: "기금액", field: "fund_amount", minWidth: 80, maxWidth: 120, type: "numericColumn", sortable: true,},
    { headerName: "잉여금", field: "remaining_amount", minWidth: 80, maxWidth: 120, type: "numericColumn", sortable: true,},
    { headerName: "입금액", field: "bank_deposit", minWidth: 80, maxWidth: 120, type: "numericColumn", sortable: true,},
    { headerName: "수수료", field: "fee", sortable: true, minWidth: 80, maxWidth: 120, type: "numericColumn",},
    { headerName: "메모", field: "memo", sortable: true, minWidth: 80, maxWidth: 120,},
  ];
};

export const getSubColumns = () => {
  return [
    { field: "seq", headerName: "순번", cellDataType: "number", edit: true, minWidth: 50, maxWidth: 80,},
    { field: "name", headerName: "이름", edit: true, minWidth: 80, maxWidth: 100,},
    { field: "item_code", headerName: "품목코드", edit: true, minWidth: 80, maxWidth: 100,},
    { field: "item_name", headerName: "품목명", edit: true, minWidth: 80, maxWidth: 100,},
    { field: "sale_quantity", headerName: "판매수량", edit: true, minWidth: 80, maxWidth: 100, type: "numericColumn", sortable: true,},
    { field: "item_price", headerName: "단가", edit: true, minWidth: 80, maxWidth: 100, type: "numericColumn", sortable: true,},
    { headerName: "추가수수료", field: "additional_fee", sortable: true, minWidth: 80, maxWidth: 120, type: "numericColumn",},
    { field: "sale_price", headerName: "세일단가", edit: true, minWidth: 80, maxWidth: 100, type: "numericColumn",},
    { field: "selling_price", headerName: "판매가", edit: true, minWidth: 50, maxWidth: 80, type: "numericColumn",},
    { field: "fund_amount", headerName: "기금액", edit: true, minWidth: 50, maxWidth: 80, type: "numericColumn",},
    { field: "deposit_amount", headerName: "입금액", edit: true, minWidth: 50, maxWidth: 80, type: "numericColumn",},
    { field: "remaining_amount", headerName: "잉여금", edit: true, minWidth: 50, maxWidth: 80, type: "numericColumn",},
    { field: "fee", headerName: "수수료", edit: true, minWidth: 50, maxWidth: 80, type: "numericColumn",},
    { field: "discount_rate_idx", headerName: "수수료율", edit: true, minWidth: 50, maxWidth: 80, type: "numericColumn",},
    { field: "member_type", headerName: "회원타입", edit: true, minWidth: 50, maxWidth: 80, type: "numericColumn",},
    { field: "discount_rate", headerName: "수수료율", edit: true, minWidth: 50, maxWidth: 80, type: "numericColumn",},
    { field: "barcode", headerName: "바코드", edit: true, minWidth: 50, maxWidth: 80, type: "numericColumn",},
  ];
};

