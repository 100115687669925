// Handlers for changes to search conditions
  export const handleConditionChange = (name, value, setSearchConditions) => {
    setSearchConditions(prevConditions => prevConditions.map(condition =>
      condition.name === name ? { ...condition, value: value } : condition
    ));
  };
  
  export const handleDateRangeChange = (name, {from, to}, setSearchConditions) => {
    setSearchConditions(prevConditions => prevConditions.map(condition =>
      condition.name === name ? { ...condition, value: { from: from ?? condition.value.from, to: to ?? condition.value.to } } : condition
    ));
  };
  
  // Convert search parameters to URL-friendly string
  export const searchParamsToString = (searchConditions) => {
    return searchConditions.map(condition => {
      switch(condition.type) {
        case 'date':
        case 'text':
        case 'select':
        case 'radio':
          return `${encodeURIComponent(condition.id)}=${encodeURIComponent(condition.value)}`;
        case 'checkbox':
          return `${encodeURIComponent(condition.id)}=${encodeURIComponent(condition.value ? '1' : '0')}`;
        case 'date-range':
          return `${encodeURIComponent(condition.id + '_from')}=${encodeURIComponent(condition.value.from)}&${encodeURIComponent(condition.id + '_to')}=${encodeURIComponent(condition.value.to)}`;
        default:
          return '';
      }
    }).join('&');
  };

  export const createSelectObject = (code) => {
    let commonCode  = localStorage.getItem('common_code');
    let codeObj = '';
    if(commonCode) {
      codeObj = JSON.parse(commonCode).find(obj => obj.common_code === code);
      // Add the logic to be executed when codeObj is found.
    } else {
        console.error("No 'common_code' found in localStorage.");
        // Add the logic to be executed when 'common_code' is not found.
    }

    if(codeObj === ''){
      return null;
    }else{
      let options = codeObj.children.map(c => ({
        value: c.detail_code,
        label: c.detail_code_name
      }));

      return { name: codeObj.common_code_name, type: 'select', value: '', options: options };
    }   
  }

  export const createSelectObjectCondition = (condition, code) => {
    //var cond = Object.assign({},condition);
    let commonCode  = localStorage.getItem('common_code');
    let codeObj = '';
    if(commonCode) {
      codeObj = JSON.parse(commonCode).find(obj => obj.common_code === code);
    } else {
        console.error("No 'common_code' found in localStorage.");
    }

    if(codeObj === ''){
      return null;
    }else{
      let options = codeObj.children.map(c => ({
        value: c.detail_code,
        label: c.detail_code_name
      }));
      //options = [{'value':undefined,'label':' '},...options];

      return { id: condition.id, name: codeObj.common_code_name, type: 'select', options: options };
    }   
  }

  