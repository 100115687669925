import React, { createContext, useState, useContext } from 'react';
import jwtDecode from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const checkTokenValidity = () => {
    const token = localStorage.getItem('token');
    if (!token || token === 'undefined') {
      return false;
    }
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp > currentTime;
    } catch (err) {
      console.error("Invalid token.", err);
      return false;
    }
  };

  const [isLoggedIn, setIsLoggedIn] = useState(checkTokenValidity());

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
