// eslint-disable-next-line no-unused-vars
import { formatAMPM, formatAmount } from "../../utils/Formatter";
// eslint-disable-next-line no-unused-vars
import { createSelectObject, createSelectOption } from '../../utils/agGridColumnUtils';

export const getColumns = () => {
  return [
    { headerName: "출금일자", field: "withdrawal_date", sortable: true, minWidth: 80, maxWidth: 120, pinned: "left",},
    { headerName: "순번", field: "sequence", sortable: true, editable: true, minWidth: 100, maxWidth: 120, pinned: "left",},
    { headerName: "통장", field: "bank_account", sortable: true, minWidth: 80, maxWidth: 90,},
    { headerName: "계정", field: "account", sortable: true, minWidth: 80, maxWidth: 100,},
    { headerName: "금액", field: "amount", editable: true, minWidth: 80, maxWidth: 120, type: "numericColumn", sortable: true,},
    { headerName: "적요", field: "summary", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "결제방법", field: "payment_method", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "거래처코드", field: "merchant_code", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "거래처명", field: "merchant_name", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "자동기표", field: "auto_mark", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "거래번호", field: "transaction_no", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "입력자", field: "input_user", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "입력시간", field: "input_time", sortable: true, filter: "agDateColumnFilter", minWidth: 170, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value),},
    { headerName: "수정자", field: "update_user", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "수정시간", field: "update_time", sortable: true, filter: "agDateColumnFilter", minWidth: 170, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value),},
  ];
};

