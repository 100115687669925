import { formatAMPM, formatAmount } from "../../utils/Formatter";
// eslint-disable-next-line no-unused-vars
import { createSelectObject } from '../../utils/agGridColumnUtils';

export const getColumns = () => {
  return [
    { field: "id", headerName: "id", hide: true, maxWidth: 120, minWidth: 100, sortable: true,},
    { field: "company_cd", headerName: "회사코드", maxWidth: 90, minWidth: 70, cellDataType: "text", sortable: true, pinned: "left", edit: true,},
    { field: "company_name", headerName: "회사명", maxWidth: 150, minWidth: 120, cellDataType: "text", sortable: true, pinned: "left", edit: true,},
    { field: "owner", headerName: "대표자", maxWidth: 100, minWidth: 80, cellDataType: "text", sortable: true, edit: true,},
    { field: "business_type", headerName: "업태", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true, edit: true,},
    { field: "business_sector", headerName: "업종", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true, edit: true,},
    { field: "address", headerName: "주소", maxWidth: 150, minWidth: 120, cellDataType: "text", sortable: true, edit: true,},
    { field: "phone_number", headerName: "전화번호", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true, edit: true,},
    { field: "business_reg_no", headerName: "사업자등록번호", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true, edit: true,},
    { field: "bank_account", headerName: "계좌번호", maxWidth: 180, minWidth: 160, cellDataType: "text", sortable: true, edit: true,},
    { field: "memo", headerName: "메모", maxWidth: 120, minWidth: 100, sortable: true, edit: true,},
    { field: "is_used", headerName: "사용여부", maxWidth: 120, minWidth: 100, sortable: true, edit: true, sideBarEditType: "checkbox",},
    { field: "is_deleted", headerName: "삭제여부", maxWidth: 120, minWidth: 100, sortable: true, edit: false, sideBarEditType: "checkbox",},
    { field: "created_by", headerName: "생성자", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "created_at", headerName: "생성일시", maxWidth: 120, minWidth: 100, cellDataType: "date", sortable: true, valueFormatter: (params) => formatAMPM(params.value),},
    { field: "updated_by", headerName: "수정자", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "updated_at", headerName: "수정일시", maxWidth: 120, minWidth: 100, cellDataType: "date", sortable: true, valueFormatter: (params) => formatAMPM(params.value),},
  ];
};