// eslint-disable-next-line no-unused-vars
import { formatAMPM, formatAmount } from "../../utils/Formatter";
// eslint-disable-next-line no-unused-vars
import { createSelectObject, createSelectOption } from '../../utils/agGridColumnUtils';

export const getColumns = () => {
  return [
    { headerName: "사업장", field: "business_place", sortable: true, minWidth: 120, maxWidth: 150, pinned: "left",},
    { headerName: "품목코드", field: "item_code", sortable: true, editable: true, minWidth: 100, maxWidth: 120, pinned: "left",},
    { headerName: "품목명", field: "item_name", sortable: true, minWidth: 120, maxWidth: 120, pinned: "left",},
    { headerName: "품목설명", field: "item_description", sortable: true, minWidth: 120, maxWidth: 160,},
    { headerName: "사용여부", field: "usage", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "품목타입", field: "item_type", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "메모", field: "memo", sortable: true, minWidth: 120, maxWidth: 150,},
    { headerName: "관수", field: "watering", sortable: true, minWidth: 80, maxWidth: 100,},
    { headerName: "온도", field: "temperature", sortable: true, minWidth: 80, maxWidth: 100,},
    { headerName: "빛", field: "light", sortable: true, minWidth: 80, maxWidth: 100,},
    { headerName: "입력자", field: "input_user", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "입력시간", field: "input_time", sortable: true, filter: "agDateColumnFilter", minWidth: 170, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value),},
    { headerName: "수정자", field: "update_user", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "수정시간", field: "update_time", sortable: true, filter: "agDateColumnFilter", minWidth: 170, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value),},
  ];
};

