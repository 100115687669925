import axios from 'axios';

export const fetchData = async (endpoint, searchConditions, params) => {
  const SERVER_ENDPOINT = 'https://work.jhseo.me:8004/';
  //const SERVER_ENDPOINT = 'http://192.168.0.13:18000/';
  try {
    const token = localStorage.getItem('token');

    const headers = {
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
    };

    const response = await axios.get(`${SERVER_ENDPOINT}${endpoint}?${params}&${searchParamsToString(searchConditions)}`, {
      headers,
      // params: {
      //   ...params,
      //   ...searchConditions,
      // },
    });

    if (response.status === 401) { // Unauthorized
      throw new Error('Unauthorized');  // 커스텀 에러 메시지를 포함한 에러 생성
    }

    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Rethrow the error so that the calling function can handle it if needed
  }
};
  
  // Convert search parameters to URL-friendly string
  export const searchParamsToString = (searchConditions) => {
    if (!searchConditions) {
      return '';
    }

    return searchConditions.map(condition => {
      switch(condition.type) {
        case 'date':
        case 'text':
        case 'select':
        case 'radio':
          return `${encodeURIComponent(condition.id)}=${encodeURIComponent(condition.value)}`;
        case 'checkbox':
          return `${encodeURIComponent(condition.id)}=${encodeURIComponent(condition.value ? '1' : '0')}`;
        case 'date-range':
          return `${encodeURIComponent(condition.id + '_from')}=${encodeURIComponent(condition.value.from)}&${encodeURIComponent(condition.id + '_to')}=${encodeURIComponent(condition.value.to)}`;
        default:
          return '';
      }
    }).join('&');
  };
  