import React, {  } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./main";
import { AuthProvider } from "./auth";  // Import AuthProvider

// Create root and modal-root elements
const rootElement = document.createElement('div');
rootElement.id = 'root';
document.body.appendChild(rootElement);

const modalRootElement = document.createElement('div');
modalRootElement.id = 'modal-root';
document.body.appendChild(modalRootElement);

const loginRootElement = document.createElement('div');
modalRootElement.id = 'login-root';
document.body.appendChild(loginRootElement);

// Render App
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Main />
    </AuthProvider>
  </React.StrictMode>
);
