import React, { useState, useEffect, useCallback, useContext } from 'react';
import jwtDecode from 'jwt-decode';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { Box, IconButton, useTheme } from "@mui/material";
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; 
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useAuth } from '../auth'; 
import { ColorModeContext } from "../theme";
import { useRecoilState, atom } from 'recoil';
import { fetchData } from "../utils/fetchUtils";

export const sidebarBrokenState = atom({
  key: `sidebarBrokenState`,
  default: [false]
});
export const sidebarToggledState = atom({
  key: `sidebarToggledState`,
  default: [false]
});


const themes = {
  light: {
    sidebar: {
      backgroundColor: '#ffffff',
      color: '#444444',
    },
    menu: {
      menuContent: '#fbfcfd',
      icon: '#0098e5',
      hover: {
        backgroundColor: '#c5e4ff',
        color: '#44596e',
      },
      disabled: {
        color: '#9fb6cf',
      },
    }
  },
  dark: {
    sidebar: {
      backgroundColor: '#081118', 
      color: '#eeeeee',//'#8ba1b7',
    },
    menu: {
      menuContent: '#092429',
      icon: '#59d0ff',
      hover: {
        backgroundColor: '#10353b',
        color: '#b6c8d9',
      },
      disabled: {
        color: '#3e5e7e',
      },
    },
  },
};

const getUserFromToken = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return null;
  }
  try {
    const decoded = jwtDecode(token);
    return decoded;
  } catch (err) {
    console.error('Error decoding token', err);
    return null;
  }
};

const fetchMenuItems = () => {
   const token = localStorage.getItem('token');
   const headers = {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }),
  };
  return fetch('https://work.jhseo.me:8004/menu/my_menus', {headers: headers}).then(response => response.json());
};

function SideBar({ openTabs, addTab, setTab }) {
  const { setIsLoggedIn } = useAuth();
  const [user, setUser] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [menuOpen, setMenuOpen] = useState(true);
  const [toggled, setToggled] = useRecoilState(sidebarToggledState);
  const [, setBroken] = useRecoilState(sidebarBrokenState);
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  //const [, setCodeState] = useRecoilState(commonCodeState); // 첫번째 반환값이 필요 없으면 무시해도 됩니다.

  const menuItemStyles = {
    root: {
      fontSize: '11.5px',
      fontWeight: 300,
    },
    icon: {
      color: themes[theme.palette.mode].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme.palette.mode].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? themes[theme.palette.mode].menu.menuContent
          : 'transparent',
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme.palette.mode].menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: themes[theme.palette.mode].menu.hover.backgroundColor,
        color: themes[theme.palette.mode].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const memoizedAPICall = useCallback(async () => {
    const codes = await fetchData('code/codes', '');
    localStorage.setItem('common_code', JSON.stringify(codes));
  }, []);

  useEffect(() => {
    setUser(getUserFromToken());
    memoizedAPICall();

    fetchMenuItems()
      .then(data => setMenuItems(data))
      .catch(console.error);
  }, [memoizedAPICall]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  const handleClick = (item) => {
    const existingTab = openTabs.find(tab => tab.menu_id === item.menu_id);

    //addTab(item);
    if (existingTab) {
      setTab(existingTab);
    } else {
      addTab(item);
    }
    setToggled(!toggled);
  };

  const handleHomeClick = () => {/* Go to Home */}

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  return (
    <div style={{ display: 'flex', height: '100%', direction: 'ltr' }}>
      <Sidebar
        collapsed={!menuOpen}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        rtl={false}
        onBreakPoint={setBroken}
        breakPoint="md"
        width='170px'
        collapsedWidth='60px'
        backgroundColor={themes[theme.palette.mode].sidebar.backgroundColor}
        rootStyles={{
          color: themes[theme.palette.mode].sidebar.color,
          borderRightWidth: '0px'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div style={{ flex: 1, marginBottom: '32px' }}>
            <div style={{ marginLeft: '5px', padding: '10px' }}>
              <div style={{ cursor: 'pointer' }} onClick={toggleMenu}>
                <MenuIcon />
                {menuOpen && (
                  <>
                    <h3 onClick={handleHomeClick}>Max POS</h3>
                    <h6>For 두구화훼단지</h6>
                  </>
                )}
              </div>
              {menuOpen && <p>안녕하세요, {user ? user.user_name : '방문자'}님!</p>}
            </div>
            <Menu menuItemStyles={menuItemStyles}>
              {menuItems.map((item) => (
                <SubMenu key={item.id} label={item.menu_name}>
                  {item.children && item.children.map((child) => (
                    <MenuItem 
                      key={child.id} 
                      item={child} 
                      onClick={() => handleClick(child)}
                    >
                      {child.menu_name}
                    </MenuItem>
                  ))}
                </SubMenu>
              ))}
            </Menu>
          </div>
          <Box display="flex">
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
            </IconButton>            
          </Box>
          <Box display="flex">            
            <IconButton onClick={handleLogout}>
              <ExitToAppIcon />
            </IconButton>
          </Box>
          <Box display="flex">
            <p></p>
          </Box>
          <Box display="flex">
            <p></p>
          </Box>
        </div>
      </Sidebar>
    </div>
  );
}

export default SideBar;