import { formatAMPM } from "../../utils/Formatter";
// eslint-disable-next-line no-unused-vars
import { createSelectObject, createSelectOption } from '../../utils/agGridColumnUtils';

export const getColumns = () => {
  return [
    { field: "user_id", headerName: "아이디", edit: true, minWidth: 30, maxWidth: 120, headerCheckboxSelection: true, checkboxSelection: true, sortable: true,},
    { field: "user_name", headerName: "이름", edit: true, minWidth: 100, maxWidth: 120, sortable: true,},
    { field: "role_cd", headerName: "권한코드", edit: true, minWidth: 30, maxWidth: 130, use_code: true, code: "ROLE", sortable: true, sideBarEditType: "select", ...createSelectOption('ROLE'),},
    { field: "phone_number", headerName: "전화번호", edit: true, minWidth: 100, maxWidth: 200, sortable: true, sideBarEditType: "text",},
    { field: "mobile_number", headerName: "휴대폰", edit: true, minWidth: 100, maxWidth: 200, sortable: true,},
    { field: "email", headerName: "이메일", edit: true, minWidth: 100, maxWidth: 200, sortable: true,},
    { field: "memo", headerName: "메모", edit: true, minWidth: 100, maxWidth: 200, sortable: true,},
    { field: "created_by", headerName: "입력자", minWidth: 30, maxWidth: 100, sortable: true,},
    { field: "created_at", headerName: "입력시간", minWidth: 100, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value), sortable: true,},
    { field: "updated_by", headerName: "수정자", minWidth: 30, maxWidth: 100, sortable: true,},
    { field: "updated_at", headerName: "수정시간", minWidth: 100, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value), sortable: true,},
  ];
};
