// eslint-disable-next-line no-unused-vars
import { formatAMPM, formatAmount } from "../../utils/Formatter";
// eslint-disable-next-line no-unused-vars
import { createSelectObject, createSelectOption } from '../../utils/agGridColumnUtils';

export const getColumns = () => {
  return [
    { headerName: "고객번호", field: "customer_code", sortable: true, editable: true, minWidth: 100, maxWidth: 120, pinned: "left",},
    { headerName: "고객명", field: "customer_name", sortable: true, minWidth: 120, maxWidth: 120, pinned: "left",},
    { headerName: "고객구분", field: "customer_type", sortable: true, minWidth: 90, maxWidth: 110, pinned: "left",},
    { headerName: "사업자등록번호", field: "business_registration_no", sortable: true, minWidth: 120, maxWidth: 160,},
    { headerName: "소유자명", field: "owner_name", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "전화", field: "phone_no", sortable: true, minWidth: 120, maxWidth: 150,},
    { headerName: "팩스", field: "fax_no", sortable: true, minWidth: 120, maxWidth: 150,},
    { headerName: "휴대폰", field: "mobile_no", sortable: true, minWidth: 120, maxWidth: 150,},
    { headerName: "주소", field: "address", sortable: true, minWidth: 120, maxWidth: 150,},
    { headerName: "상세주소", field: "detailed_address", sortable: true, minWidth: 120, maxWidth: 150,},
    { headerName: "업태", field: "business_type", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "종목", field: "sector", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "메모", field: "memo", sortable: true, minWidth: 120, maxWidth: 150,},
    { headerName: "입출구분", field: "entry_exit", sortable: true, minWidth: 120, maxWidth: 140,},
    { headerName: "암호", field: "password", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "사용여부", field: "usage", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "우편번호", field: "postcode", sortable: true, minWidth: 120, maxWidth: 120,},
    { headerName: "지역", field: "region", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "문자발송", field: "text_send", sortable: true, minWidth: 140, maxWidth: 170,},
    { headerName: "이메일", field: "email", sortable: true, minWidth: 120, maxWidth: 120,},
    { headerName: "은행코드", field: "bank_code", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "계좌번호", field: "account_no", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "RFID", field: "rfid", sortable: true, minWidth: 100, maxWidth: 120,},
    { headerName: "입력자", field: "input_user", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "입력시간", field: "input_time", sortable: true, filter: "agDateColumnFilter", minWidth: 170, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value),},
    { headerName: "수정자", field: "update_user", sortable: true, minWidth: 80, maxWidth: 120,},
    { headerName: "수정시간", field: "update_time", sortable: true, filter: "agDateColumnFilter", minWidth: 170, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value),},
  ];
};

