import React, { useMemo, useState } from 'react';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
//import "ag-grid-community/styles/ag-theme-alpine.css";
import { useTheme } from "@mui/material";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@mui/material/Button';
import './CustomGrid.css';
import { columnDefsGenerator } from '../utils/agGridColumnUtils';

export const CustomGrid = ({ gridProps }) => {
  
  // eslint-disable-next-line no-unused-vars
  const [editingValue, setEditingValue] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [editingRow, setEditingRow] = useState(null);

  const onCellEditingStarted = (params) => {
    const { data } = params;
    //const { keyColumn } = gridProps;
  
    // Check if the row status code is 'D' (deleted)
    if (data.rowStatusCode === 'D') {
      alert('삭제된 행은 수정할 수 없습니다.');
      return;
    }
  
    setEditingValue(params.value);
    setEditingRow(data);
  };

  const onCellEditingStopped = (params) => {
    const { keyColumn, rowData, setRowData } = gridProps;
    const { colDef, data, oldValue, newValue } = params;
  
    if (colDef.field === keyColumn && oldValue !== newValue) {
      const duplicate = rowData.find(
        (row) => row !== data && row[keyColumn] === newValue
      );
  
      if (duplicate) {
        alert('중복된 키 값이 있습니다. 다른 값을 입력해주세요.');
        // Revert the changed value back to the original
        setRowData((prevRowData) =>
          prevRowData.map((row) => {
            if (row === data) {
              return { ...row, [keyColumn]: oldValue };
            }
            return row;
          })
        );
      } else {
        //If no duplicate, update the row data
        const updatedRowData = {
          ...data,
          rowStatusCode: data.rowStatusCode === 'I' ? 'I' : 'U',
          rowStatusName: data.rowStatusName === '추가' ? '추가' : '변경',
          [colDef.field]: newValue,
        };
        setRowData((prevRowData) =>
          prevRowData.map((row) =>
            row[keyColumn] === data[keyColumn] ? updatedRowData : row
          )
        );
      }
    } else if (oldValue !== newValue) {
      // Updating row status if a cell value has changed
      const updatedRowData = {
        ...data,
        rowStatusCode: data.rowStatusCode === 'I' ? 'I' : 'U',
        rowStatusName: data.rowStatusName === '추가' ? '추가' : '변경',
        [colDef.field]: newValue,
      };
      setRowData((prevRowData) =>
        prevRowData.map((row) =>
          row[keyColumn] === data[keyColumn] ? updatedRowData : row
        )
      );
    }
  };
  

  const theme = useTheme();
  
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 180,
      filter: true,
      floatingFilter: false,
      sortable: true,
      resizable: true,
      editable: false,
    };
  },[]);

  const dataTypeDefinitions = useMemo(() => {
    return {
      object: {
        baseDataType: "object",
        extendsDataType: "object",
        valueParser: (params) => ({ name: params.newValue }),
        valueFormatter: (params) =>
          params.value == null ? "" : params.value.name,
      },
    };
  }, []);

  const rowClassRules = useMemo(() => {
    return {
      'ag-edited': (params) => {
        var rowStatusCode = params.data.rowStatusCode;
        return rowStatusCode === 'I' || rowStatusCode === 'U' || rowStatusCode === 'D';
      },
    };
  }, []);

  const ActionCellRenderer = (params) => {
    return (
      <span>
        <Button size="small" color={theme.palette.mode === "dark"?"secondary":"primary"} startIcon={<EditIcon style={{paddingLeft:'0px',paddingBottom:'3px'}} size="small" fontSize="inherit"/>} onClick={() => gridProps.onRowEdit(params.data)}>
          수정
        </Button>
        <Button size="small" color={theme.palette.mode === "dark"?"secondary":"primary"} startIcon={<DeleteIcon style={{paddingBottom:'3px'}} size="small" fontSize="inherit"/>} onClick={() => gridProps.onRowDelete(params.data)}>
          삭제
        </Button>
      </span>
    )
  };

  return (
    <div style={{ height: "100%", width: "100%" }} className={theme.palette.mode === "dark"?"ag-theme-balham-dark":"ag-theme-balham"}>
      <AgGridReact
        ref={gridProps.gridRef}
        rowData={gridProps.rowData}
        columnDefs={gridProps.isReport?gridProps.columnDefs:columnDefsGenerator(gridProps.columnDefs,ActionCellRenderer)}
        defaultColDef={gridProps.defaultColDef|| defaultColDef}
        dataTypeDefinitions={gridProps.dataTypeDefinitions || dataTypeDefinitions}
        onGridReady={gridProps.onGridReady}
        onRowClicked={gridProps.onRowClicked}
        rowHeight={28}
        onCellValueChanged={gridProps.onCellValueChanged}
        onCellEditingStarted={onCellEditingStarted}
        onCellEditingStopped={onCellEditingStopped}
        rowSelection={'multiple'}
        rowClassRules={rowClassRules}
        enableRangeSelection={true}
        copyHeadersToClipboard={false}
        suppressRowTransform={true}
        //columnHoverHighlight={true}
        //singleClickEdit={true}
        //enableGroupEdit={true}
        // frameworkComponents={{
        //   selectRenderer: SelectRenderer,
        //   selectEditor: SelectRenderer,
        // }}
      />
    </div>
  );
};
