import React, { useCallback, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { EditBar, EditBarContent } from "../components/EditBar";
import { CustomGrid } from "../components/CustomGrid";
import SearchConditions from "../components/SearchConditions";
import { fetchData } from "../utils/fetchUtils";
import useSaveData from '../utils/useSaveData';
import {
  handleConditionChange,
  handleDateRangeChange,
} from "../utils/SearchConditionUtils";
import LoadingOverlay from "../components/LoadingOverlay";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import CustomButton from "../components/CustomButton";
import { Confirm } from '../components/Confirm';


const CommonMenuTemplate2 = ({
  menuId,
  menuName,
  searchConditionsState,
  itemsPerRow,
  searchMainApi,
  searchSubApi,
  saveMainApi,
  saveSubApi,
  mainGridProps,
  subGridProps,
  mainGridWidth,
  subGridWidth,
  mainKeyColumn,
  subKeyColumn,
  subKeyHiddenSetColumn,
  onChangeMain,
  onChangeSub,
  resetChangedMain,
  resetChangedSub,
  isReport,
  handleGridResultMessage
}) => {
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //const [newRowData, setNewRowData] = useState({});
  const [sideEditBarOpen, setSideEditBarOpen] = useState(false);
  const [currentColumns,] = useState([]);

  const [editableRow, setEditableRow] = useState({});

  const [loading, setLoading] = useState(false);
  const [searchConditions, setSearchConditions] = useRecoilState(
    searchConditionsState
  );
  const [mainRowData, setMainRowData] = useState([]);
  const [subRowData, setSubRowData] = useState([]);

  //const [changedRows, setChangedRows] = useState([]);

  const [isConfirming, setIsConfirming] = useState(false);

  const mainGridRef = useRef();
  const subGridRef = useRef();

  const [selectedGrid, setSelectedGrid] = useState("main");

  //const [clickedMainRow, setClickedMainRow] = useState(null);
  //const [clickedSubRow, setClickedSubRow] = useState(null);

  const [checkedRows, setCheckedRows] = useState([]);

  const [mainKey, setMainKey] = useState(null);

  //const [previousMainRowId, setPreviousMainRowId] = useState(null);

  const setRowStatus = useCallback((row, statusCode, statusName) => {
    return {...row, rowStatusCode: statusCode, rowStatusName: statusName};
  }, []);

  const handleAddButtonClick = useCallback(() => {
    const newRow = currentColumns.reduce((obj, column) => ({
      ...obj, 
      [column.field]: ""
    }), {});
    
    if(selectedGrid === 'sub'){
      newRow[subKeyHiddenSetColumn] = mainKey;
    }
    
    setEditableRow(setRowStatus(newRow, 'I', '추가'));
    setSideEditBarOpen(true);
  }, [currentColumns, setRowStatus, subKeyHiddenSetColumn, selectedGrid, mainKey]);

  const setMainRowDataFn = (rows) => {
    setMainRowData(rows);
  };
  
  const setSubRowDataFn = (rows) => {
    setSubRowData(rows);
  };

  const onFetchSubData = useCallback(async (code) => {
    setLoading(true);
    setMainKey(code);
    const data = await fetchData(searchSubApi + '/' +code, searchConditions);
    setSubRowData(data);
    //setChangedRows([]);
    setLoading(false);
    resetChangedSub();
    handleGridResultMessage(`${mainGridRef.current.api.rowModel.rowsToDisplay.length}건, ${data.length}건 조회 되었습니다.`);
  }, [searchSubApi, searchConditions, resetChangedSub, handleGridResultMessage]);

  const onFetchMainData = useCallback(async () => {
    setLoading(true);
    const data = await fetchData(searchMainApi, searchConditions);
    setMainRowData(data);
    //setChangedRows([]);
    if(data.length > 0){
      onFetchSubData(data[0][mainKeyColumn]);
    }else{
      setSubRowData([]);
    }
    setLoading(false);
    resetChangedMain();
  }, [searchMainApi, searchConditions, resetChangedMain, mainKeyColumn, onFetchSubData]);

  const [saveData] = useSaveData(saveMainApi, saveSubApi, onFetchMainData, onFetchSubData);

  const onGridReadyMain = useCallback(() => {
    if (!mainRowData || mainRowData.length < 1) {
      onFetchMainData();
    }
  }, [mainRowData, onFetchMainData]);

  const onMainRowClicked = (event) => {
    //setClickedMainRow(event.data);

    // 클릭된 행의 ID를 저장합니다.
    //setPreviousMainRowId(event.data[mainKeyColumn]);

    setSelectedGrid("main");
    onFetchSubData(event.data[mainKeyColumn]);
  };

  const onSubRowClicked = (event) => {
    //setClickedSubRow(event.data);
    setSelectedGrid("sub");
  };

  // 일괄삭제 팝업창
  const handleDeleteButtonClick = useCallback(() => {
    let selectedData = [];
    console.log(subGridRef);
    let selectedNodes = selectedGrid === 'main'?mainGridRef.current.api.selectionService.selectedNodes:subGridRef.current.api.selectionService.selectedNodes;
    if(Object.keys(selectedNodes).length > 0){
      for(let i=0;i<Object.keys(selectedNodes).length;i++){
        selectedData.push(selectedNodes[Object.keys(selectedNodes)[i]].data);
      }
    }
    if(selectedData.length > 0){
      setIsConfirming(true);
      setCheckedRows(selectedData);
    }else{
      alert('선택된 데이터가 없습니다.');
    }
  }, [selectedGrid, mainGridRef, subGridRef]);

  // 일괄삭제 삭제처리
  const onConfirmSave = useCallback(() => {     
    const updatedCheckedRows = checkedRows.map(row => setRowStatus(row, 'D', '삭제'));
    console.log(updatedCheckedRows);
    saveData(updatedCheckedRows, selectedGrid);
    setIsConfirming(false);
    onFetchMainData();
  }, [saveData, checkedRows, onFetchMainData, setRowStatus, selectedGrid]);

  const onConfirmClose = useCallback(() => {
    setIsConfirming(false);
  }, []);

  //수정 창 띄우기
  const onMainRowEdit = useCallback((data) => {
    setEditableRow(setRowStatus(data, 'U', '수정'));
    setSideEditBarOpen(true);
  }, [setRowStatus]);

  //삭제 창 띄우기
  const onMainRowDelete = useCallback((data) => {
    setEditableRow(setRowStatus(data, 'D', '삭제'));    
    setSideEditBarOpen(true);
  }, [setRowStatus]);

  //수정 창 띄우기
  const onSubRowEdit = useCallback((data) => {
    setEditableRow(setRowStatus(data, 'U', '수정'));
    setSideEditBarOpen(true);
  }, [setRowStatus]);

  //삭제 창 띄우기
  const onSubRowDelete = useCallback((data) => {
    setEditableRow(setRowStatus(data, 'D', '삭제'));    
    setSideEditBarOpen(true);
  }, [setRowStatus]);

  const commonGridPropsMain = {
    rowData: mainRowData,
    columnDefs: mainGridProps.columnDefs,
    defaultColDef: mainGridProps.defaultColDef,
    dataTypeDefinitions: mainGridProps.dataTypeDefinitions,
    onGridReady: mainGridProps.onGridReady || onGridReadyMain,
    onRowClicked: mainGridProps.onRowClicked || onMainRowClicked,
    gridRef: mainGridRef,
    keyColumn: mainKeyColumn,
    setRowData: setMainRowDataFn,
    onRowEdit: onMainRowEdit,
    onRowDelete: onMainRowDelete,
    isReport:isReport
  };

  const commonGridPropsSub = {
    rowData: subRowData,
    columnDefs: subGridProps.columnDefs,
    defaultColDef: subGridProps.defaultColDef,
    dataTypeDefinitions: subGridProps.dataTypeDefinitions,
    onRowClicked: subGridProps.onRowClicked || onSubRowClicked,
    gridRef: subGridRef,
    keyColumn: subKeyColumn,
    setRowData: setSubRowDataFn,
    onRowEdit: onSubRowEdit,
    onRowDelete: onSubRowDelete,
    isReport:isReport
  };

  const handleMainGridClick = (event) => {
    let targetElement = event.target;
    while (targetElement != null) {
      if (targetElement.classList.contains('ag-root')) {
        setSelectedGrid('main');  // Change this to 'sub' for the sub grid
        break;
      }
      targetElement = targetElement.parentElement;
    }
  };

  const handleSubGridClick = (event) => {
    let targetElement = event.target;
    while (targetElement != null) {
      if (targetElement.classList.contains('ag-root')) {
        setSelectedGrid('sub');  // Change this to 'sub' for the sub grid
        break;
      }
      targetElement = targetElement.parentElement;
    }
  };

  const handleEditBarOperation = useCallback(() => {
    let existingRow;    
    if(selectedGrid === 'main'){
      if(editableRow.rowStatusCode === 'I'){
        existingRow = mainRowData.find(
          (row) => row[mainKeyColumn] === editableRow[mainKeyColumn]
        );
      }
      if (existingRow && editableRow.rowStatusCode === 'I') {
        alert("중복된 키 값이 있습니다. 다른 값을 입력해주세요.");
        return;
      }
      saveData([editableRow], 'main');
    }else{
      if(editableRow.rowStatusCode === 'I'){
        existingRow = subRowData.find(
          (row) => row[subKeyColumn] === editableRow[subKeyColumn]
        );
      }
      if (existingRow && editableRow.rowStatusCode === 'I') {
        alert("중복된 키 값이 있습니다. 다른 값을 입력해주세요.");
        return;
      }
      saveData([editableRow], 'sub');
    }
    setSideEditBarOpen(false);
  }, [selectedGrid, mainRowData, subRowData, mainKeyColumn, subKeyColumn, editableRow, saveData]);

  const handleCancelEditBar = useCallback(() => {
    setSideEditBarOpen(false);
  }, []);

  const renderConfirm = useCallback(() => {
    return isConfirming ?
      <Confirm
        open = {isConfirming}
        titleMessage="삭제 확인"
        detailMessage="선택된 데이터를 모두 삭제 하시겠습니까?"
        handleConfirm={onConfirmSave}
        handleClose={onConfirmClose}
      /> : null;
  }, [isConfirming, onConfirmSave, onConfirmClose]);

  const editBarContent = <EditBarContent 
                              columnDefs={(selectedGrid === 'main')?mainGridProps.columnDefs:subGridProps.columnDefs} 
                              editableRow={editableRow} 
                              setEditableRow={setEditableRow} 
                              handleEditBarOperation={handleEditBarOperation} 
                              handleCancelEditBar={handleCancelEditBar} />;                              

  const gridElementMain = <CustomGrid gridProps={commonGridPropsMain} />;
  const gridElementSub = <CustomGrid gridProps={commonGridPropsSub} />;

  return (
    <div style={{ height: "100%" }}>
      <EditBar open={sideEditBarOpen} onClose={handleCancelEditBar} content={editBarContent} />
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{
            border: "1px solid #AAA",
            borderColor:
                theme.palette.mode === "dark" ? "#555" : "#BBB",
            borderRadius: "5px",
            padding: "1px",
            marginTop: "2px",
            marginBottom: "5px",
          }}
        >
          <div
            style={{
              backgroundColor:
                theme.palette.mode === "dark" ? colors.primary[700] : "#f5f5f5",
              padding: "10px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <SearchConditions
              searchConditions={searchConditions}
              setSearchConditions={setSearchConditions}
              onConditionChange={(name, value) =>
                handleConditionChange(name, value, setSearchConditions)
              }
              onDateRangeChange={(name, { from, to }) =>
                handleDateRangeChange(name, { from, to }, setSearchConditions)
              }
              onSearch={onFetchMainData}
              itemsPerRow={itemsPerRow}
            />
          </div>
        </div>
        <div className="toolbar">
        {!isReport && <>
          <CustomButton type={"ADD"} text={"신규"} onClick={handleAddButtonClick}/>
          <CustomButton type={"DELETE"} text={"삭제"} onClick={handleDeleteButtonClick}/>
          </>
        }
          <CustomButton type={"SEARCH"} text={"조회"} onClick={onFetchMainData}/>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            overflow: "auto",
          }}
        >
          <div 
            onClick={handleMainGridClick}
            style={{ width: mainGridWidth, height: "100%", overflow: "auto" }}
          >
            {gridElementMain}
          </div>
          <div style={{ width: "1px" }}></div>
          <div
            onClick={handleSubGridClick}
            style={{ width: subGridWidth, height: "100%", overflow: "auto" }}
          >
            {gridElementSub}
          </div>
        </div>
      </div>
      {loading && <LoadingOverlay />}
      {renderConfirm()}
    </div>
  );
};

export default CommonMenuTemplate2;
