import moment from 'moment';

export const dateFormatter = new Intl.DateTimeFormat(navigator.language);
export const currencyFormatter = new Intl.NumberFormat(navigator.language, { style: 'currency', currency: 'eur' });

export const formatAMPM = date => {
  if (date)
    return moment(date).format('YYYY-MM-DD ') + 
    (moment(date).format('A') === 'AM' ? '오전 ' : '오후 ') + 
    moment(date).format('hh:mm:ss');
  else
    return null;
}

export const formatAmount = number => {
  if (number) {
    // 숫자를 천단위 콤마가 있는 형식으로 변환하여 반환
    return number.toLocaleString();
  } else {
    return '0'; // 숫자가 없을 경우 문자열 '0' 반환
  }
};
  

export const calculateDate = days => {
  const date = new Date();
  date.setDate(date.getDate() + days);

  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // 월은 0부터 시작하므로 1을 더해줍니다.
  const day = ('0' + date.getDate()).slice(-2);
  
  return `${year}-${month}-${day}`;
}
  