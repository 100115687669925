import { formatAMPM } from "../../utils/Formatter";
// eslint-disable-next-line no-unused-vars
import { createSelectObject, createSelectOption } from '../../utils/agGridColumnUtils';

export const getColumns = () => {
  return [
    { field: "common_code", headerName: "코드", edit: true, minWidth: 80, maxWidth: 150, sideBarEditType: "text", headerCheckboxSelection: true, checkboxSelection: true,},
    { field: "common_code_name", headerName: "코드명", edit: true, minWidth: 150, maxWidth: 300, sideBarEditType: "text",},
    { field: "is_used", headerName: "사용여부", edit: true, minWidth: 80, maxWidth: 100, cellDataType: "boolean", sideBarEditType: "checkbox",},
    { field: "system", headerName: "시스템", editable: true, minWidth: 80, maxWidth: 100, use_code: true, code: "CA10", sideBarEditType: "select",},
    { field: "remarks", headerName: "비고", edit: true, minWidth: 100, maxWidth: 200, sideBarEditType: "number",},
    { field: "created_by", headerName: "입력자", minWidth: 80, maxWidth: 140,},
    { field: "created_at", headerName: "입력시간", sideBarEditType: "date", minWidth: 100, maxWidth: 220, valueFormatter: (params) => formatAMPM(params.value),},
    { field: "updated_by", headerName: "수정자", minWidth: 80, maxWidth: 140,},
    { field: "updated_at", headerName: "수정시간", minWidth: 100, maxWidth: 220, valueFormatter: (params) => formatAMPM(params.value),},
  ];
};
