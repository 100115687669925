import { atomFamily } from 'recoil';
// eslint-disable-next-line no-unused-vars
import { calculateDate } from "../../utils/Formatter";

const id = 'CF002';
export const rowsState = atomFamily({
  key: (tabKey) => `${id}-rowsState${tabKey}`,
  default: []
});

export const loadingState = atomFamily({
  key: (tabKey) => `${id}-loadingState${tabKey}`,
  default: false
});

export const searchConditionsState = atomFamily({
  key: (tabKey) => `${id}-searchConditionsState${tabKey}`,
  default: [    
    { id: 'customer_code', name: '고객코드', type: 'text', value: '' },
    { id: 'customer_name', name: '고객명', type: 'text', value: '' },

    { id: 'usage', name: '사용여부', type: 'text', value: '' },
    { id: 'entry_exit', name: '매입매출구분', type: 'text', value: '' },

    { id: 'rfid', name: 'RFID', type: 'text', value: '' },
    { id: 'phone_no', name: '전화/휴대폰', type: 'text', value: '' },
  ]
});
 
export const searchConditionitemsPerRow = 5;