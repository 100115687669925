import { atomFamily } from 'recoil';
import { calculateDate } from "../../utils/Formatter";
import { createSelectObject } from '../../utils/SearchConditionUtils';

const id = 'SA002';

export const rowsMainState = atomFamily({
  key: (tabKey) => `${id}-rowsMainState${tabKey}`,
  default: []
});

export const rowsSubState = atomFamily({
  key: (tabKey) => `${id}-rowsSubState${tabKey}`,
  default: []
});

export const loadingState = atomFamily({
  key: (tabKey) => `${id}-loadingState${tabKey}`,
  default: false
});

export const modifiedMainRowsState = atomFamily({
  key: (tabKey) => `${id}-modifiedMainRowsState${tabKey}`,
  default: false
});

export const modifiedSubRowsState = atomFamily({
  key: (tabKey) => `${id}-modifiedSubRowsState${tabKey}`,
  default: false
});

export const searchConditionsState = atomFamily({
  key: (tabKey) => `${id}-searchConditionsState${tabKey}`,
  default: (tabKey) => {
    return [
      { id: 'sales_date', name: '매출일자', type: 'date', value: calculateDate(-27) },
      { id: 'transaction_no', name: '매출번호', type: 'text', value: '' },
    ]
  }
});

export const searchConditionitemsPerRow = 3;