// eslint-disable-next-line no-unused-vars
import { formatAMPM, formatAmount } from "../../utils/Formatter";
// eslint-disable-next-line no-unused-vars
import { createSelectObject, createSelectOption, getDefaultRowSpan, getDefaultCellSpanRule } from '../../utils/agGridColumnUtils';

export const getColumns = () => {
  return [
    { field: "transaction_no", editable: true, headerName: "매출번호", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true, pinned: "left",},// cellSpan: true, rowSpan: getDefaultRowSpan, cellClassRules: {'cell-span': getDefaultCellSpanRule},},
    { field: "cell_span", headerName: "cell_span", hide: true, maxWidth: 120, minWidth: 100, sortable: true,},
    { field: "seq", headerName: "순번", maxWidth: 70, minWidth: 90, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value), pinned: "left",},
    { field: "merchant_code", headerName: "고객코드", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "merchant_name", headerName: "고객명", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "name", headerName: "대표명", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "mobile_phone", headerName: "휴대폰", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "member_code", headerName: "회원코드", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "item_code", headerName: "품목코드", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "item_name", headerName: "품목명", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "sale_quantity", headerName: "판매수량", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { field: "deleted_quantity", headerName: "삭제수량", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { field: "item_price", headerName: "품목단가", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { field: "additional_fee", headerName: "추가수수료", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { field: "sale_price", headerName: "세일단가", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { field: "selling_price", headerName: "판매가", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { field: "fund_amount", headerName: "기금액", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { field: "deposit_amount", headerName: "입금액", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { field: "remaining_amount", headerName: "잉여금", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { headerName: "입력시간", field: "input_time", sortable: true, filter: "agDateColumnFilter", minWidth: 170, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value), cellDataType: "date",},
    { headerName: "수정시간", field: "update_time", sortable: true, filter: "agDateColumnFilter", minWidth: 170, maxWidth: 200, valueFormatter: (params) => formatAMPM(params.value), cellDataType: "date",},
    { field: "fee", headerName: "수수료", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { field: "discount_rate_idx", headerName: "할인율코드", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { field: "member_type", headerName: "회원타입", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "fluctuation_rate", headerName: "증감율", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { field: "discount_rate", headerName: "할인율", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { field: "fund_rate", headerName: "기금율", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { field: "fee_rate", headerName: "수수료율", maxWidth: 120, minWidth: 100, cellDataType: "number", type: "numericColumn", sortable: true, valueFormatter: (params) => formatAmount(params.value),},
    { field: "barcode", headerName: "바코드", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "memo", headerName: "메모", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "input_user", headerName: "입력자", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "update_user", headerName: "수정자", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "input_worker", headerName: "입력근무자코드", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "input_worker_name", headerName: "입력근무자명", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "update_worker", headerName: "수정근무자코드", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
    { field: "update_worker_name", headerName: "수정근무자명", maxWidth: 120, minWidth: 100, cellDataType: "text", sortable: true,},
  ];
};