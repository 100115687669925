import React, { useState } from 'react';
import CommonMenuTemplate2 from '../CommonMenuTemplate2';
import { getMainColumns, getSubColumns } from './AD003Columns';
import { searchConditionsState, searchConditionitemsPerRow } from './AD003State';

const AD003 = ({ tab, onChangeMain, onChangeSub, resetChangedMain, resetChangedSub, handleGridResultMessage }) => {
  const [mainColumnDefs] = useState(getMainColumns);
  const [subColumnDefs] = useState(getSubColumns);

  const menuMainGridProps = {
    columnDefs: mainColumnDefs,
  };

  const menuSubGridProps = {
    columnDefs: subColumnDefs,
  };

  return (
      <CommonMenuTemplate2
        menuId={tab.menu_id}
        menuName={tab.menu_name}
        searchConditionsState={searchConditionsState(tab.key)}
        itemsPerRow={searchConditionitemsPerRow}
        searchMainApi={'menu/root_menus'}
        searchSubApi={'menu/sub_menus'}
        saveMainApi={'menu/menus'}
        saveSubApi={'menu/menus'}
        mainGridProps={menuMainGridProps}
        subGridProps={menuSubGridProps}
        mainGridWidth={"40%"}
        subGridWidth={"60%"}
        mainKeyColumn={'menu_id'}
        subKeyColumn={'menu_id'}
        subKeyHiddenSetColumn={'parent_menu'}
        useModal={false}
        onChangeMain={onChangeMain}
        onChangeSub={onChangeSub}
        resetChangedMain={resetChangedMain}
        resetChangedSub={resetChangedSub}
        isReport={false}
        handleGridResultMessage={handleGridResultMessage}
      />
  );
};

export default AD003;
